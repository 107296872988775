import { refreshSession } from "src/utils/utils";
import client from "../client";
import publicClient from "../publicClient";
import store from "src/page/store";
import { sendNotificationTemplate, sendNotificationType } from "src/types/commonTypes";

export const getUiConfig = () => {
  return publicClient({
    query: `
    query GetUiConfig {
      get_ui_config {
        value {
          file_templates {
            bucket_name
            key
            file_type
          }
          static_file_templates {
            bucket_name
            key
            file_type
            file_name
            name
          }
          menu_list {
            id
            name
            highlight
          }
          role_referral_program_mapping {
            id
            name
          }
          mcc_category {
            id
            name
          }
          permission_list {
            id
            name
          }
          cash_config {
            menu_list {
              id
              name
            }
            role_mapping {
              role
              permissions
              menu_list
              roles_allowed_to_add
              params {
                switch_organisation_option
                payment_template
              }
            }
          }
          web_config {
            menu_list {
              id
              name
            }
            role_mapping {
              role
              permissions
              menu_list
              roles_allowed_to_add
              params {
                switch_organisation_option
                payment_template
              }
            }
          }
          internal_web_config {
            menu_list {
              id
              name
            }
            file_types {
              id
              name
            }
            role_mapping {
              role
              permissions
              menu_list
              roles_allowed_to_add
              params {
                switch_organisation_option
                payment_template
              }
            }
          }
        }
      }
    }
    `
  });
};

export type SwitchOrganisationRequest = {
  organisationId: string;
  userId: string;
};
export const switchOrganisation = async (data: SwitchOrganisationRequest) => {
  const { auth } = store.getState();

  const switchOrganisationMethod =
    auth.cognitoConfig.params?.switch_organisation_option === "switch_internal_organisation"
      ? "switch_internal_user_organisation"
      : "switch_organisation";

  const inputType =
    auth.cognitoConfig.params?.switch_organisation_option === "switch_internal_organisation"
      ? "SwitchInternalUserOrganisationInput"
      : "SwitchOrganisationInput";

  const input: {
    organisation_id: string;
    user_id: string;
    sub_type?: string;
  } = {
    organisation_id: data.organisationId,
    user_id: data.userId
  };

  if (switchOrganisationMethod === "switch_organisation") {
    input.sub_type = "CURRENT";
  }

  if (auth.cognitoRefreshToken != null) {
    await client({
      query: `
        mutation SwitchOrganisation($input: ${inputType}) {
          ${switchOrganisationMethod}(input: $input) {
            organisation_id
          }
        }
      `,
      variables: {
        input
      }
    });

    return refreshSession(auth.cognitoRefreshToken);
  }
  return Promise.reject();
};

export const resetPassword = async (userId: string, cognitoUserName: string) => {
  return client({
    query: `mutation ResetUserPassword($input: UpdateUserPasswordInput) { update_user_password(input: $input) }`,
    variables: {
      input: {
        user_id: userId,
        cognito_username: cognitoUserName
      }
    }
  });
};

export interface upgradePlanRequest {
  user_name: string;
  user_email: string;
  user_id: string;
  organisation_name: string;
  organisation_id: string;
}

export const upgradePlan = async (data: upgradePlanRequest) => {
  return client({
    query: `mutation UpgradePlan($input: SendUpgradeEmailInput) { 
      send_upgrade_email(input: $input) { 
        message 
        code
      } 
    }`,
    variables: {
      input: { variables: data }
    }
  });
};

export interface sendNotificationRequest {
  variables: {
    user_name: string;
    user_email: string;
    user_id: string;
    organisation_name: string;
    organisation_id: string;
    display_id: string;
  };
  targets: string[];
  type: sendNotificationType;
  template: sendNotificationTemplate;
}

export const sendNotification = (data: sendNotificationRequest) => {
  return client({
    query: `
    mutation SendNotification($input: SendNotificationInput) {
      send_notification(input: $input) {
        message
      }
    }`,
    variables: {
      input: data
    }
  });
};
