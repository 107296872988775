import { createSlice } from "@reduxjs/toolkit";
import {
  TransactionDownloadRequest,
  TransactionRequest,
  createNote,
  deletePhoto,
  downloadInternalTransaction,
  getActivity,
  getAllInternalTransaction,
  listNote,
  mockCardPurchase,
  updateGstAmount,
  updateNote
} from "src/network/graphql/transactionService";
import { User } from "src/types/commonTypes";
import { StoreDispatch } from "../store";
import { Wallet } from "../wallets/walletSlice";
import { InternalSearchWallet, SearchWalletRequest, getWalletDetail } from "src/network/graphql/walletService";
import { SearchUserRequest, getUserById, searchInternalUser } from "src/network/graphql/userService";
import { immediateToast } from "izitoast-react";
import { initialWalletDetail } from "../walletDetail/walletDetailSlice";
import { initialUserDetail } from "../userDetail/userDetailSlice";
import { Note, Transaction } from "../transactions/transactionSlice";

const initialTransaction: Transaction[] = [];

const initialNote: Note[] = [];

const initialUsers: User[] = [];

const initialWallets: Wallet[] = [];

type TransactionData = {
  id: number;
  wallet: {
    participant: { id: number; email: string; firstName: string; lastName: string; role: string };
    description: string;
    balance: number;
    status: boolean;
    createdOn: number;
    updatedOn: number;
  };
  merchantName: string;
  amount: number;
  date: number;
  balance: number;
  categoryName: string;
  receiptPresent: boolean;
  maskedCardNumber: string;
  note: string;
  purchaseMadeBy: { id: number; email: string; firstName: string; lastName: string };
  itemCategoryId: number;
  authledgerId: number;
  shouldUpdateItemCategory: boolean;
  receipts: {
    id: number;
    receiptUrl: string;
  }[];
  photos: {
    id: number;
    photoUrl: string;
  }[];
};

const initialTransDetail: TransactionData = {
  id: 0,
  wallet: {
    participant: { id: 0, email: "", firstName: "", lastName: "", role: "" },
    description: "",
    balance: 0,
    status: false,
    createdOn: 0,
    updatedOn: 0
  },
  merchantName: "",
  amount: 0,
  date: 0,
  balance: 0,
  categoryName: "",
  receiptPresent: false,
  maskedCardNumber: "",
  note: "",
  purchaseMadeBy: { id: 0, email: "", firstName: "", lastName: "" },
  itemCategoryId: 0,
  authledgerId: 0,
  shouldUpdateItemCategory: false,
  receipts: [],
  photos: []
};

type recieptPhoto = {
  data: string;
};

const initialRecieptPhoto: recieptPhoto = {
  data: ""
};

type uploadRecieptPhoto = {
  data: string;
};

const initialUploadRecieptPhoto: uploadRecieptPhoto = {
  data: ""
};

type masterList = {
  id: number;
  categoryName: string;
};

const initialMasterList: masterList[] = [];

const internalTransactionSlice = createSlice({
  name: "transactions",
  initialState: {
    transLoading: false,
    userDetailLoading: false,
    mockCardLoading: false,
    userLoading: false,
    walletLoading: false,
    paymentDataLoading: false,
    deletePhotoLoading: false,
    noteLoading: false,
    walletDetailLoading: false,
    error: null,
    transList: {
      list: initialTransaction,
      total: 0
    },
    userDetail: initialUserDetail,
    users: {
      list: initialUsers,
      total: 0
    },
    walletList: {
      list: initialWallets,
      total: 0
    },
    transDetail: initialTransDetail,
    transRecieptPhoto: initialRecieptPhoto,
    uploadRecieptPhoto: initialUploadRecieptPhoto,
    mastersList: {
      total: 0,
      list: initialMasterList
    },
    notes: initialNote,
    walletDetail: initialWalletDetail
  },
  reducers: {
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.list = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.list = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    fetchUserDetailSuccess: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = action.payload ?? initialUserDetail;
    },
    fetchUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = initialUserDetail;
      state.error = action.payload;
    },
    setUsers: (state, action) => {
      state.users = {
        list: action.payload,
        total: action.payload?.length || 0
      };
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    setWallets: (state, action) => {
      state.walletList = {
        list: action.payload,
        total: action.payload?.length || 0
      };
    },
    downloadTransactionStart: (state) => {
      state.paymentDataLoading = true;
    },
    downloadTransactionSuccess: (state) => {
      state.paymentDataLoading = false;
    },
    downloadTransactionFail: (state, action) => {
      state.paymentDataLoading = false;
      state.error = action.payload;
    },
    mockCardStart: (state) => {
      state.mockCardLoading = true;
      state.error = null;
    },
    mockCardSuccess: (state) => {
      state.mockCardLoading = false;
    },
    mockCardFail: (state, action) => {
      state.mockCardLoading = false;
      state.error = action?.payload;
    },
    getNoteStart: (state) => {
      state.noteLoading = true;
    },
    getNoteSuccess: (state, action) => {
      state.noteLoading = false;
      state.notes = action.payload;
    },
    getNoteFail: (state, action) => {
      state.noteLoading = false;
      state.error = action.payload;
    },
    changeNoteStart: (state) => {
      state.noteLoading = true;
    },
    changeNoteSuccess: (state) => {
      state.noteLoading = false;
    },
    changeNoteFail: (state, action) => {
      state.noteLoading = false;
      state.error = action.payload;
    },
    deletePhotoStart: (state) => {
      state.deletePhotoLoading = true;
    },
    deletePhotoSuccess: (state) => {
      state.deletePhotoLoading = false;
    },
    deletePhotoFail: (state, action) => {
      state.deletePhotoLoading = false;
      state.error = action.payload;
    },
    fetchWalletDetailStart: (state) => {
      state.walletDetailLoading = true;
    },
    fetchWalletDetailSuccess: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail.wallet = action.payload.wallet ?? initialWalletDetail.wallet;
    },
    fetchWalletDetailFail: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail = initialWalletDetail;
      state.error = action.payload;
    },
    updateGstAmountStart: (state) => {
      state.noteLoading = true;
    },
    updateGstAmountSuccess: (state) => {
      state.noteLoading = false;
    },
    updateGstAmountFail: (state, action) => {
      state.noteLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  downloadTransactionStart,
  downloadTransactionSuccess,
  downloadTransactionFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  mockCardStart,
  mockCardSuccess,
  mockCardFail,
  getNoteStart,
  getNoteSuccess,
  getNoteFail,
  changeNoteStart,
  changeNoteSuccess,
  changeNoteFail,
  deletePhotoStart,
  deletePhotoSuccess,
  deletePhotoFail,
  fetchWalletDetailStart,
  fetchWalletDetailSuccess,
  fetchWalletDetailFail,
  fetchUserDetailStart,
  fetchUserDetailSuccess,
  fetchUserDetailFail,
  updateGstAmountStart,
  updateGstAmountSuccess,
  updateGstAmountFail
} = internalTransactionSlice.actions;

export const { setUsers, setWallets } = internalTransactionSlice.actions;

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllInternalTransaction(data);
      const structuredResponse = response.data.internal_search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.created_at,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        receipt_available: data.receipt_available,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.internal_search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};
export const fetchTransDataAction = (id: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getActivity({ activity_id: id });
      const structuredResponse = response.data.get_activity;
      const formattedResponse = {
        id: structuredResponse.activity_id,
        name: structuredResponse.activity_name,
        endDate: structuredResponse.end_timestamp,
        startDate: structuredResponse.created_at,
        ownerId: structuredResponse.owner_id,
        activityStatus: structuredResponse.activity_status,
        receipt_available: structuredResponse.receipt_available,
        approval_number: structuredResponse.approval_number,
        data: JSON.parse(structuredResponse?.data)
      };

      dispatch(
        fetchTransListSuccess({
          list: [formattedResponse],
          total: 1
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export const downloadTransactionAction = (data: TransactionDownloadRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(downloadTransactionStart());
    try {
      const response = await downloadInternalTransaction(data);
      const downloadUrl = response.data.internal_download_transactions.download_url;

      if (downloadUrl) {
        window.open(downloadUrl, "_blank");
      }

      if (
        !response.data.internal_download_transactions.count ||
        response.data.internal_download_transactions.count === 0
      ) {
        immediateToast("error", {
          message: "No Transaction Found",
          timeout: 3000,
          position: "topCenter"
        });
      }
      dispatch(downloadTransactionSuccess());
    } catch (err) {
      dispatch(downloadTransactionFail(err));
    }
  };
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchInternalUser({
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.internal_search_user.user_listing.map((user: any) => ({
        id: user.user_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: "not available",
        status: user.is_active,
        userId: user.user_id
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.internal_search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await InternalSearchWallet(data);

      const structuredResponse = response.data.internal_search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          email: wallet?.user?.email
        }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: response.data.internal_search_wallet.total_size
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const mockPurchaseAction = (cardId: string, amount: number, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(mockCardStart());
    try {
      const response = await mockCardPurchase(cardId, amount);

      immediateToast("success", {
        message: "Mock purchase successfully",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(mockCardSuccess());
      if (cb) cb();
      return response;
    } catch (err) {
      dispatch(mockCardFail(err));
    }
  };
};

export const getNoteAction = (transactionId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getNoteStart());
    try {
      const response = await listNote(transactionId);
      const structuredResponse = response.data.list_notes.note.map((data: any) => ({
        noteId: data.note_id,
        message: data.payload.message
      }));

      dispatch(getNoteSuccess(structuredResponse));
    } catch (err) {
      dispatch(getNoteFail(err));
    }
  };
};

export const createNoteAction = (transactionId: string, note: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changeNoteStart());
    try {
      await createNote(transactionId, note);
      dispatch(changeNoteSuccess());
      if (cb) cb();
    } catch (err) {
      dispatch(changeNoteFail(err));
    }
  };
};

export const updateNoteAction = (noteId: string, note: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changeNoteStart());
    try {
      await updateNote(noteId, note);
      dispatch(changeNoteSuccess());
      if (cb) cb();
    } catch (err) {
      dispatch(changeNoteFail(err));
    }
  };
};

export const deleteFile = (assetId: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deletePhotoStart());
    try {
      await deletePhoto(assetId);

      immediateToast("success", {
        message: "photo successfully deleted.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(deletePhotoSuccess());
      if (cb) cb();
    } catch (err) {
      dispatch(deletePhotoFail(err));
    }
  };
};

export const fetchWalletDetailAction = (data: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailStart());
    try {
      const walletResponse = await getWalletDetail(data);
      const wallet = walletResponse.data.get_wallet;
      const structuredWalletResponse = {
        name: wallet?.name,
        description: wallet?.description,
        balance: wallet?.balance,
        id: wallet?.wallet_id
      };

      dispatch(
        fetchWalletDetailSuccess({
          wallet: structuredWalletResponse
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletDetailFail(error));
    }
  };
};

export const fetchUserDetailAction = (data: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDetailStart());
    try {
      const response = await getUserById({ user_id: data });
      const user = response.data.get_user;

      dispatch(
        fetchUserDetailSuccess({
          user: {
            id: user.user_id,
            initial: user.attributes.initial,
            firstName: user.attributes.first_name,
            lastName: user.attributes.last_name,
            email: user.email
          }
        })
      );
    } catch (error) {
      dispatch(fetchUserDetailFail(error));
    }
  };
};

export const updateGstAmountAction = (id: string, amount: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateGstAmountStart());
    try {
      await updateGstAmount(id, amount);
      dispatch(updateGstAmountSuccess());
    } catch (error) {
      dispatch(updateGstAmountFail(error));
    }
  };
};

export default internalTransactionSlice.reducer;
