import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  createUser,
  type CreateUserRequest,
  //   searchUser,
  SuspendUserRequest,
  suspendUser,
  getUserByEmail,
  ConnectUserRequest,
  connectUser,
  searchInternalUser,
  userOrganisation
} from "src/network/graphql/userService";
import { immediateToast } from "izitoast-react";
import { UserDetail } from "../userDetail/userDetailSlice";
import { Users } from "../users/usersSlice";
import { initialOrgList } from "../organisation/organisationSlice";
import { OrganisationFilterType, internalOrganisationFilter } from "src/network/graphql/organisationService";

const initialUsers: Users[] = [];

const initialUserData: UserDetail = {
  user: {
    id: 0,
    cognitoUserName: "",
    displayId: "",
    active: false,
    email: "",
    userName: "",
    initial: "Mr.",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    gender: "male",
    state: "",
    city: "",
    line1: "",
    line2: "",
    zipcode: "",
    country: "",
    role: [],
    accessCode: "",
    fullName: "",
    guardianId: 0,
    kycStatus: "Fail",
    profileImage: ""
  },
  guardian: null,
  canDoActiveInactive: false,
  canEditRole: false,
  canEditGuardian: false,
  canEditKycStatus: false
};

export type UserOrganisations = {
  id: string;
  displayId: string;
  name: string;
  role: string;
  status: string;
};

const initialUserOrganisations: UserOrganisations[] = [];

const internalUserSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: false,
    userDataLoading: false,
    userOrganisationsLoading: false,
    orgListLoading: false,
    connectUserLoading: false,
    suspenduserLoading: false,
    error: null,
    users: {
      list: initialUsers,
      total: 0
    },
    userData: initialUserData,
    showSetPassword: false,
    setPasswordLoading: false,
    userOrganisations: initialUserOrganisations,
    orgLists: {
      list: initialOrgList,
      total: 0
    }
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    addUserStart: (state) => {
      state.userLoading = true;
    },
    addUserSuccess: (state) => {
      state.userLoading = false;
    },
    addUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    connectUserStart: (state) => {
      state.connectUserLoading = true;
    },
    connectUserSuccess: (state) => {
      state.connectUserLoading = false;
    },
    connectUserFail: (state, action) => {
      state.connectUserLoading = false;
      state.error = action.payload;
    },
    suspendUserStart: (state) => {
      state.suspenduserLoading = true;
    },
    suspendUserSuccess: (state) => {
      state.suspenduserLoading = false;
    },
    suspendUserFail: (state, action) => {
      state.suspenduserLoading = false;
      state.error = action.payload;
    },
    getUserByEmailStart: (state) => {
      state.userDataLoading = true;
    },
    getUserByEmailSuccess: (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload ?? initialUserData;
    },
    getUserByEmailFail: (state, action) => {
      state.userDataLoading = false;
      state.userData = initialUserData;
      state.error = action.payload;
    },
    setShowSetPassword: (state, action) => {
      state.showSetPassword = action.payload;
    },
    setSetPasswordLoading: (state, action) => {
      state.setPasswordLoading = action.payload;
    },
    getUserOrganisationsStart: (state) => {
      state.userOrganisationsLoading = true;
    },
    getUserOrganisationsSuccess: (state, action) => {
      state.userOrganisationsLoading = false;
      state.userOrganisations = action.payload ?? [];
    },
    getUserOrganisationsFail: (state, action) => {
      state.userOrganisationsLoading = false;
      state.userOrganisations = [];
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    removeOrgList: (state, action) => {
      state.orgLists.list = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  addUserStart,
  addUserSuccess,
  addUserFail,
  connectUserStart,
  connectUserSuccess,
  connectUserFail,
  suspendUserStart,
  suspendUserSuccess,
  suspendUserFail,
  getUserByEmailStart,
  getUserByEmailFail,
  getUserByEmailSuccess,
  getUserOrganisationsStart,
  getUserOrganisationsSuccess,
  getUserOrganisationsFail,
  fetchOrgListStart,
  fetchOrgListSuccess,
  fetchOrgListFail
} = internalUserSlice.actions;

export const { setSetPasswordLoading, setShowSetPassword, removeOrgList } = internalUserSlice.actions;

export const fetchUserListAction = (data: {
  isActive?: boolean | null;
  email?: string;
  fullName?: string;
  organisationId?: string | null;
  limit?: number;
  from?: number;
  role?: string;
}) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchInternalUser({
        user_filter: {
          is_active: data.isActive,
          email: data.email,
          full_name: data.fullName,
          organisation_id: data.organisationId,
          role: data.role
        },
        size: data.limit,
        from: data.from
      });

      const structuredResponse = response.data.internal_search_user.user_listing.map((user: any) => ({
        id: user?.display_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active,
        userId: user?.user_id,
        createdAt: user?.created_at
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.internal_search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const addUserAction = (data: CreateUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addUserStart());
    try {
      const response = (await createUser(data)) as unknown as { errors?: Error[] };

      dispatch(addUserSuccess());
      immediateToast("success", {
        message: "User Successfully Added.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
      return response;
    } catch (error) {
      dispatch(addUserFail(error));
    }
  };
};

export const connectUserAction = (data: ConnectUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(connectUserStart());
    try {
      await connectUser(data);

      dispatch(connectUserSuccess());
      immediateToast("success", {
        message: `User Successfully Connected.`,
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
    } catch (error) {
      dispatch(connectUserFail(error));
    }
  };
};

export const suspendUserAction = (data: SuspendUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(suspendUserStart());
    try {
      await suspendUser(data);

      dispatch(suspendUserSuccess());
      immediateToast("success", {
        message: "User successfully unlinked from the organization.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
    } catch (error) {
      dispatch(suspendUserFail(error));
    }
  };
};

export const getUserByEmailAction = (email: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserByEmailStart());

    try {
      const response = await getUserByEmail(email);
      const user = response.data.get_user;

      dispatch(
        getUserByEmailSuccess({
          user: {
            id: user.user_id,
            displayId: user.display_id,
            email: user.email
          }
        })
      );
    } catch (error) {
      dispatch(getUserByEmailFail(error));
    }
  };
};

export const getUserOrganisationsAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserOrganisationsStart());

    try {
      const response = await userOrganisation(userId);
      const userOrganisations = response.data.get_user_organisations.map((data: any) => ({
        id: data.organisation_id,
        displayId: data.display_id,
        name: data.name,
        role: data.role
      }));

      dispatch(getUserOrganisationsSuccess(userOrganisations));
    } catch (error) {
      dispatch(getUserOrganisationsFail(error));
    }
  };
};

export const fetchOrgListAction = (data: OrganisationFilterType) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await internalOrganisationFilter(data);

      const structuredResponse = response.data.search_organisation.organisation_listing.map((org: any) => ({
        organisationId: org.organisation_id,
        id: org.display_id,
        active: org.is_active,
        name: org.name,
        createdAt: org.created_at,
        referenceName: org.contact.name,
        phone: org.contact.phone
      }));

      dispatch(
        fetchOrgListSuccess({
          list: structuredResponse,
          total: response.data.search_organisation.total_size
        })
      );
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

export default internalUserSlice.reducer;
