import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { SearchCardSetting, deleteCardSetting, searchCardSetting } from "src/network/graphql/userService";
import { Wallet } from "../wallets/walletSlice";
import { SearchWalletRequest, searchWallet } from "src/network/graphql/walletService";
import { Card } from "../cards/cardSlice";
import { CardSearchRequest, internalCardSearch } from "src/network/graphql/cardService";
import { immediateToast } from "izitoast-react";

export type CardSettingType = {
  card_setting_id: string;
  card_setting_status: string;
  status: string;
  ppan: string;
  card_id: string;
  wallet_id: string;
  expires_at: string;
  sub_type: string;
  user_id: string;
};

const initialCardSetting: CardSettingType[] = [];

const initialWallets: Wallet[] = [];

const initialCards: Card[] = [];

const cardSettingSlice = createSlice({
  initialState: {
    loading: false,
    cardLoading: false,
    walletLoading: false,
    error: null,
    cards: {
      list: initialCardSetting,
      total: 0
    },
    walletList: {
      list: initialWallets,
      total: 0
    },
    cardList: {
      list: initialCards,
      total: 0
    }
  },
  name: "cardSetting",
  reducers: {
    getAllCardsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cards = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    setWallets: (state, action) => {
      state.walletList = {
        list: action.payload,
        total: action.payload?.length || 0
      };
    },
    fetchCardListStart: (state) => {
      state.cardLoading = true;
    },
    fetchCardListSuccess: (state, action) => {
      state.cardLoading = false;
      state.cardList.list = action.payload?.list ?? [];
      state.cardList.total = action.payload?.total || 0;
    },
    fetchCardListFail: (state, action) => {
      state.cardLoading = false;
      state.cardList.list = [];
      state.cardList.total = 0;
      state.error = action.payload;
    },
    setCards: (state, action) => {
      state.cardList = {
        list: action.payload,
        total: action.payload?.length || 0
      };
    },
    deleteCardSettingStart: (state) => {
      state.loading = true;
    },
    deleteCardSettingSuccess: (state) => {
      state.loading = false;
    },
    deleteCardSettingFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const {
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchCardListStart,
  fetchCardListSuccess,
  fetchCardListFail,
  deleteCardSettingStart,
  deleteCardSettingSuccess,
  deleteCardSettingFail
} = cardSettingSlice.actions;

export const { setWallets, setCards } = cardSettingSlice.actions;

export const getCardSettingAction = (data: SearchCardSetting) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await searchCardSetting(data);
      const structuredResponse = response.data.search_card_settings.items?.map((card: any) => ({
        card_setting_id: card?.card_setting_id,
        card_setting_status: card?.card_setting_status,
        status: card?.status,
        ppan: card?.ppan,
        card_id: card?.card_id,
        wallet_id: card?.wallet_id,
        expires_at: card?.expires_at,
        sub_type: card?.sub_type,
        user_id: card?.user_id,
        updated_at: card?.updated_at
      }));

      dispatch(
        getAllCardsSuccess({
          list: structuredResponse,
          total: response.data.search_card_settings.total_size
        })
      );
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};

export const fetchWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          email: wallet?.user?.email
        }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: response.data.search_wallet.total_size
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchCardListAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchCardListStart());
    try {
      const response = await internalCardSearch(data);
      const structuredResponse = response?.data?.internal_search_card.card_listing.map((card: any) => ({
        id: card?.card_id,
        cardStatus: card?.card_status,
        cardNumber: card?.ppan,
        cardHolderName: card.holder_name,
        user: {
          id: card?.user_id,
          fullName:
            card?.holder_name != null && card?.holder_name.trim() !== ""
              ? card?.holder_name
              : card?.user?.attributes.first_name + " " + card?.user?.attributes.last_name
        }
      }));

      dispatch(
        fetchCardListSuccess({
          list: structuredResponse,
          total: response.data.internal_search_card.total_size
        })
      );
    } catch (error) {
      dispatch(fetchCardListFail(error));
    }
  };
};

export const deleteCardSettingAction = (cardId: string, walletId: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteCardSettingStart());
    try {
      const response = (await deleteCardSetting(cardId, walletId)) as unknown as { errors?: Error[] };

      if (response.errors) {
        immediateToast("error", {
          message: response.errors[0].message,
          timeout: 3000,
          position: "topCenter"
        });
      } else {
        immediateToast("success", {
          message: "Card Setting removed successfully",
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(deleteCardSettingSuccess());
        if (cb) cb();
      }
    } catch (error) {
      dispatch(deleteCardSettingFail(error));
    }
  };
};

export default cardSettingSlice.reducer;
