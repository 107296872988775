import { useAppDispatch } from "src/page/store";
import styles from "./orgReferralRedirectLink.module.scss";
import { getUserId } from "src/page/organisation/organisationSlice";
import { useNavigate } from "react-router-dom";

interface OrgReferralRedirectLinkProp {
  referralCode: string;
}
export default function OrgReferralRedirectLink({ referralCode }: OrgReferralRedirectLinkProp) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const redirectToUser = () => {
    if (referralCode) {
      dispatch(
        getUserId(referralCode, (id: string) => {
          navigate(`/users/${id}`);
        })
      );
    }
  };

  return (
    <div className={referralCode ? styles.referralLink : ""} onClick={redirectToUser}>
      {referralCode ?? "---"}
    </div>
  );
}
