import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./uploadFiles.module.scss";
import { Button, MenuItem, Select, FormControl, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import { getSignedUrlFromS3, uploadFileToS3, emailValidation, getOrdinalSuffix } from "src/utils/utils";
import { immediateToast } from "izitoast-react";
import { sendCapmpaignProgramEmailAction } from "./uploadFilesSlice";
import { sendNotificationRequest } from "src/network/graphql/configService";

export default function UploadFiles() {
  const dispatch = useAppDispatch();
  const [type, setType] = useState<string | null>(null);
  const [error, setError] = useState({ type: "", file: "", email: "" });
  const { staticFileTemplate, userInfo, cognitoConfig } = useAppSelector((state) => state.auth);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailString, setEmailString] = useState<string>("");
  const [validEmails, setValidEmails] = useState<string[]>([]);

  const handleChange = (File: File) => {
    setError({ ...error, file: "" });
    setFile(File);
  };

  const handleEmailBlur = () => {
    const emails = emailString
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email);

    setValidEmails(emails);

    if (emails.length > 50) {
      setError({ ...error, email: "You cannot enter more than 50 emails." });
      return;
    }

    const invalidEmailIndex = emails.findIndex((email) => !emailValidation(email.trim().toLowerCase()));

    if (invalidEmailIndex !== -1) {
      setError({
        ...error,
        email: `Your ${invalidEmailIndex + 1}${getOrdinalSuffix(invalidEmailIndex + 1)} email is invalid`
      });
    } else {
      setError({ ...error, email: "" });
    }
  };

  const uploadFile = async () => {
    if (!type?.trim() || !file) {
      setError({
        ...error,
        type: type?.trim() ? "" : "Select a type",
        file: file ? "" : "Select a file"
      });
      return;
    }

    if (type === "DEFAULT_CAMPAIGN_PROGRAM") {
      if (validEmails.length === 0) {
        setError({
          ...error,
          email: "Please enter a email"
        });
        return;
      }
    }

    const fileTemp = staticFileTemplate?.find((fileItem) => fileItem.file_type === type);

    if (fileTemp) {
      const data = {
        file: file,
        fileName: `${fileTemp?.key}/${fileTemp?.file_name}`,
        S3Bucket: fileTemp?.bucket_name,
        MetaData: {
          file_type: fileTemp?.file_type,
          date: moment().format("YYYY-MM-DD"),
          original_filename: fileTemp?.file_name
        },
        contentType: file.type
      };

      setLoading(true);
      await uploadFileToS3(data);
      if (type === "DEFAULT_CAMPAIGN_PROGRAM") {
        const sendPromises = validEmails
          .filter((email) => emailValidation(email.trim().toLowerCase()))
          .map((email) => {
            if (userInfo && userInfo?.email && cognitoConfig?.currentOrganisation) {
              const emailData: sendNotificationRequest = {
                variables: {
                  user_name: userInfo?.fullName,
                  user_email: userInfo?.email,
                  user_id: userInfo?.id,
                  organisation_name: cognitoConfig?.currentOrganisation?.organisation_name,
                  organisation_id: cognitoConfig?.currentOrganisation?.organisation_id,
                  display_id: userInfo?.displayId
                },
                targets: [email.trim().toLowerCase()],
                type: "email",
                template: "referral"
              };

              return dispatch(sendCapmpaignProgramEmailAction(emailData));
            }
          });

        Promise.all(sendPromises)
          .then(() => {
            immediateToast("success", {
              message: "Email sent successfully",
              timeout: 3000,
              position: "topCenter"
            });
          })
          .catch((error) => {
            console.error("Error sending invitations:", error);
          });
      }
    }
    setLoading(false);
    setType(null);
    setFile(null);
    setValidEmails([]);
    setEmailString("");
    setError({
      type: "",
      file: "",
      email: ""
    });
  };

  const downloadFile = async () => {
    if (!type?.trim()) {
      setError({
        ...error,
        type: type?.trim() ? "" : "Select a type"
      });

      return;
    }

    const fileTemp = staticFileTemplate?.find((fileItem) => fileItem.file_type === type);

    if (fileTemp) {
      setLoading(true);
      getSignedUrlFromS3(fileTemp.bucket_name, fileTemp.key + "/" + fileTemp.file_name, true)
        .then((url: any) => {
          window.open(url, "_blank");
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setType(null);
          setFile(null);
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.uploadFileWrapper}>
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"Upload Files"} subHeading={"Upload your all kind of files here"} />
      </div>
      <div className={styles.uploadCard}>
        <div className={styles.uploadType}>
          <span className={styles.head}>Type</span>
          <FormControl>
            <Select
              value={type || ""}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setError({ ...error, type: "" });
                }
                setType(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: 50,
                color: {
                  NOVATTI: "",
                  DEPOSIT: "",
                  "": "grey",
                  default: "grey"
                }[type ?? "default"]
              }}
            >
              <MenuItem value="" sx={{ color: "grey" }}>
                Select type
              </MenuItem>
              {staticFileTemplate?.map((File) => (
                <MenuItem key={File.file_type} value={File.file_type}>
                  {File.name}
                </MenuItem>
              ))}
            </Select>
            {error.type && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.type}
              </div>
            )}
          </FormControl>
          {type === "DEFAULT_CAMPAIGN_PROGRAM" && (
            <div className={styles.emailInputContainer}>
              <textarea
                value={emailString}
                onChange={(e) => {
                  if ((e.target.value.match(/,/g) || []).length >= 50) {
                    setError({ ...error, email: "You cannot enter more than 50 emails." });
                  } else {
                    setError({ ...error, email: "" });
                    setEmailString(e.target.value);
                  }
                }}
                onBlur={handleEmailBlur}
                placeholder="Enter emails separated by commas"
                rows={5}
                className={styles.emailTextArea}
              />
              {error.email && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.email}
                </div>
              )}
            </div>
          )}
          <div className={styles.fileUploadContainer}>
            <FileUploader
              multiple={false}
              file
              handleChange={handleChange}
              name="file"
              types={["HTML"]}
              classes={styles.uploadFile}
            />
            {error.file && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.file}
              </div>
            )}
            <p>{file ? `File name: ${file.name}` : null}</p>
          </div>
        </div>
        <div className={styles.actionButton}>
          <Button
            aria-label="button"
            className={styles.downloadBtn}
            variant="outlined"
            onClick={downloadFile}
            disabled={loading}
          >
            {loading ? <CircularProgress sx={{ color: "#0f172a" }} /> : "Download Template"}
          </Button>
          <Button
            aria-label="button"
            className={styles.uploadBtn}
            variant="contained"
            onClick={uploadFile}
            disabled={loading}
          >
            {loading ? <CircularProgress sx={{ color: "white" }} /> : "Upload File"}
          </Button>
        </div>
      </div>
    </div>
  );
}
