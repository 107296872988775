import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { initialOrgDetail } from "../organisationDetail/organisationDetailSlice";
import { getOrganisationDetail } from "src/network/graphql/organisationService";
import { sendNotification } from "src/network/graphql/configService";
import { sendNotificationRequest } from "src/network/graphql/configService";

const referralProgramSlice = createSlice({
  name: "referralProgram",
  initialState: {
    loading: false,
    organisationDetailLoading: false,
    orgDetail: initialOrgDetail,
    error: null
  },
  reducers: {
    sendInvitationStart: (state) => {
      state.loading = true;
    },
    sendInvitationSuccess: (state) => {
      state.loading = false;
    },
    sendInvitationFalse: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getOrgDetailStart: (state) => {
      state.organisationDetailLoading = true;
    },
    getOrgDetailSuccess: (state, action) => {
      state.orgDetail.organisation = action.payload ?? initialOrgDetail;
      state.organisationDetailLoading = false;
    },
    getOrgDetailFail: (state, action) => {
      state.organisationDetailLoading = false;
      state.orgDetail = initialOrgDetail;
      state.error = action.payload;
    }
  }
});

const {
  sendInvitationStart,
  sendInvitationSuccess,
  sendInvitationFalse,
  getOrgDetailStart,
  getOrgDetailSuccess,
  getOrgDetailFail
} = referralProgramSlice.actions;

export const sendReferralInvitationAction = (data: sendNotificationRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(sendInvitationStart());
    try {
      await sendNotification(data);

      dispatch(sendInvitationSuccess());
      if (cb) cb();
      return true;
    } catch (err) {
      dispatch(sendInvitationFalse(err));
      return false;
    }
  };
};

export const getOrgDetailAction = (id: number | string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgDetailStart());
    try {
      const response = await getOrganisationDetail(id);

      const organisation = response.data.get_organisation;
      const structuredOrganisation = {
        id: organisation.display_id,
        organisationId: organisation.organisation_id,
        name: organisation.name,
        phone: organisation.phone,
        type: organisation.type,
        abn: organisation.abn,
        referenceRole: organisation.contact.role,
        address: organisation.address.city,
        active: organisation.is_active,
        referencePhone: organisation.contact.phone,
        referenceEmail: organisation.contact.email,
        refereneRole: organisation.contact.role,
        referenceName: organisation.contact.name,
        linkedIn: organisation.social?.linkedin,
        facebook: organisation.social?.facebook,
        instagram: organisation.social?.instagram,
        website: organisation.social?.website,
        city: organisation.address.city,
        line1: organisation.address.line_1,
        line2: organisation.address.line_2,
        state: organisation.address.state,
        postcode: organisation.address.post_code,
        country: organisation.address.country
      };

      dispatch(getOrgDetailSuccess(structuredOrganisation));
    } catch (error) {
      dispatch(getOrgDetailFail(error));
    }
  };
};

export default referralProgramSlice.reducer;
