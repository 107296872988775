import { useCallback, useEffect, useState } from "react";
import styles from "./mcc.module.scss";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import Table from "rc-table";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import SearchBar from "src/components/UI/searchBar/SearchBar";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { searchMccAction } from "src/page/mcc/mccSlice";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import mccColumns from "./mccConfig";
import { LIMIT, PAGE } from "src/components/constant/queryConstants";
import { useSearchParams } from "react-router-dom";

export default function MCC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { loading, mccList } = useAppSelector((state) => state.mcc);
  const { mccCategory } = useAppSelector((state) => state.auth);
  const [categoryFilter, setCategoryFilter] = useState<string>("All");
  const [searchText, setSearchText] = useState<string>("");

  const page = searchParams.get(PAGE) ?? "0";
  const rowsPerPage = searchParams.get(LIMIT) ?? "10";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(LIMIT, event.target.value as string);
    searchParams.set(PAGE, "0");
    setSearchParams(searchParams);
  };

  const fetchMccData = useCallback(() => {
    let pageNo = parseInt(page);
    let limit = parseInt(rowsPerPage);

    const filter: any = {
      mcc_filter: {}
    };

    if (searchText && searchText !== "") {
      filter.mcc_filter.mcc = [searchText];

      pageNo = 0;
      limit = 10;
    }

    if (categoryFilter !== "All") {
      filter.mcc_filter.mcc_categories = [categoryFilter];
    }

    dispatch(
      searchMccAction({
        filter,
        size: limit,
        from: pageNo * limit
      })
    );
  }, [page, rowsPerPage, searchText, categoryFilter, dispatch]);

  useEffect(() => {
    fetchMccData();
  }, [fetchMccData]);

  const handleCategoryChange = (value: string) => {
    searchParams.set(PAGE, "0");
    setSearchParams(searchParams);
    setCategoryFilter(value);
  };

  return (
    <div className={styles.transactions}>
      <div className={styles.transactionWelCard}>
        <TransactionWelCard
          heading={"View MCC details"}
          subHeading={"View MCC details of all the transactions on your wallets"}
        />
      </div>
      <div className={styles.transactionTableCard}>
        <div className={styles.transactionList} aria-label="MCC List">
          <div className={styles.transactionListHead}>
            <div className={styles.transactionListHeadIn}>
              <div className={styles.transactionListHeadD}>
                <h3>MCC List</h3>
                <p>Filter, select, and download MCCs</p>
                <FormControl sx={{ m: 1, margin: 0, minWidth: 150 }}>
                  <Select
                    value={categoryFilter}
                    label="Category Filter"
                    onChange={(e) => {
                      handleCategoryChange(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Category Filter" }}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value="All">All Categories</MenuItem>
                    {mccCategory.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.transactionListHeadBtn}>
                <div className={styles.cardListSearch}>
                  <SearchBar
                    onSearch={(v) => {
                      setSearchText(v);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.transactionListTable}>
            <Table
              columns={mccColumns}
              data={loading ? [] : mccList?.list}
              rowKey="mcc_id"
              className={styles.tableIn}
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
            <div className={styles.paginationWrapper}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={mccList?.total ?? 0}
                page={parseInt(page)}
                onPageChange={handleChangePage}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
