import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { MccRequest, searchMCC } from "src/network/graphql/transactionService";

type MccList = {
  mcc_id: string;
  title: string;
  mcc: string;
  mcc_category: string;
};

export const initialMccList: MccList[] = [];

const mccSlice = createSlice({
  name: "mcc",
  initialState: {
    loading: false,
    error: null,
    mccList: {
      list: initialMccList,
      total: 0
    }
  },
  reducers: {
    fetchMccStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMccSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.mccList = action?.payload;
    },
    fetchMccFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const { fetchMccStart, fetchMccSuccess, fetchMccFail } = mccSlice.actions;

export const searchMccAction = (filter: MccRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchMccStart());
    try {
      const response = await searchMCC(filter);

      dispatch(
        fetchMccSuccess({
          list: response.data?.search_mcc?.items,
          total: response?.data?.search_mcc?.total_size
        })
      );
    } catch (err) {
      dispatch(fetchMccFail(err));
    }
  };
};

export default mccSlice.reducer;
