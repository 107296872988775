import styles from "./blockedWalletMerchant.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import EditIcon from "@mui/icons-material/Edit";
import { WalletPreferenceRequest } from "src/network/graphql/walletService";
import { Avatar, Button } from "@mui/material";
import {
  changePreferenceAction,
  fetchWalletDetailAction,
  getMerchantAction,
  walletDetail
} from "src/page/walletDetail/walletDetailSlice";
import { useEffect, useState } from "react";
import { bgColorbyName } from "src/variable/randomColor";
import { CAN_BLOCK_MERCHANT } from "../constant/constant";
import { checkPermission } from "src/utils/utils";

interface Props {
  data: walletDetail;
}

export default function BlockedWalletMerchant({ data }: Props) {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.walletDetail);
  const [editMerchant, setEditMerchant] = useState(false);
  const [selectedMerchantIds, setSelectedMerchantIds] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getMerchantAction(data?.wallet?.preferences?.blockedMerchants ?? []));
  }, [dispatch, data?.wallet?.preferences?.blockedMerchants]);

  const handleCheckboxChange = (merchantId: string) => {
    setSelectedMerchantIds((prevSelected) =>
      prevSelected.includes(merchantId) ? prevSelected.filter((id) => id !== merchantId) : [...prevSelected, merchantId]
    );
  };

  const handleDelete = () => {
    const blockedMerchantIds = data?.wallet?.preferences?.blockedMerchants?.filter(
      (merchantId) => !selectedMerchantIds.includes(merchantId)
    );

    const preferenceData: WalletPreferenceRequest = {
      cashWithdrawalAllowed: data?.wallet?.preferences?.cashWithdrawalAllowed,
      maxAllowedLimit: data?.wallet?.preferences?.maxAllowedLimit,
      spendFrequency: data?.wallet?.preferences?.spendFrequency,
      spendLimit: data?.wallet?.preferences?.spendLimit,
      blockedMccCategories: data?.wallet?.preferences?.blockedMccCategories,
      walletId: String(data?.wallet?.id),
      blockedMerchantIds
    };

    dispatch(changePreferenceAction(preferenceData)).then(() => {
      setEditMerchant(false);
      dispatch(fetchWalletDetailAction(String(data?.wallet?.id)));
    });
  };

  return (
    <div className={styles.referModalBox}>
      <header className={styles.referModalTop}>
        <div className={styles.heading}>Blocked Merchants</div>
        {checkPermission(CAN_BLOCK_MERCHANT) &&
          data?.wallet?.preferences?.blockedMerchants?.length > 0 &&
          (editMerchant ? (
            <Button className={styles.saveBtn} variant="contained" onClick={handleDelete}>
              Unblock
            </Button>
          ) : (
            <div className={styles.closeBtn} onClick={() => setEditMerchant(true)}>
              <EditIcon className={styles.editIcon} />
            </div>
          ))}
      </header>
      <main className={styles.referModalBottom}>
        {merchant?.length > 0 ? (
          merchant?.map((merch) => (
            <div className={styles.merchant} key={merch?.external_id}>
              <div className={styles.merchantItem}>
                <Avatar
                  id="av"
                  className={styles.av}
                  aria-label="Recipe"
                  style={{ backgroundColor: bgColorbyName(merch?.name) }}
                >
                  {merch?.name?.charAt(0).toLocaleUpperCase()}
                </Avatar>
                <div className={styles.merchantInfo}>
                  <span className={styles.name}>{merch?.name}</span>
                  <span className={styles.id}>{merch?.external_id}</span>
                </div>
              </div>
              {editMerchant && (
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={selectedMerchantIds.includes(merch?.external_id)}
                  onChange={() => handleCheckboxChange(merch?.external_id)}
                />
              )}
            </div>
          ))
        ) : (
          <div className={styles.noData}>
            <span>No Blocked Merchants</span>
          </div>
        )}
      </main>
    </div>
  );
}
