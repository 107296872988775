import Modalui from "src/components/UI/modal/Modalui";
import styles from "./referralProgram.module.scss";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Button, CircularProgress, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";
// import { countryCodeData } from "src/types/countryCode";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
import { emailValidation, getSignedUrlFromS3 } from "src/utils/utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrgDetailAction, sendReferralInvitationAction } from "./referralProgramSlice";
import { immediateToast } from "izitoast-react";
import { sendNotificationRequest } from "src/network/graphql/configService";
import { sendNotificationType } from "src/types/commonTypes";
import { countryCodeData } from "src/types/countryCode";
import parsePhoneNumberFromString from "libphonenumber-js";

export default function ReferralProgram() {
  const [open, setOpen] = useState<boolean>(true);
  const [type, setType] = useState<sendNotificationType>("email");
  const [pageUrl, setPageUrl] = useState<string>("");
  const dispatch = useAppDispatch();
  const { userInfo, cognitoConfig, roleReferralProgram, staticFileTemplate } = useAppSelector((state) => state.auth);
  const { loading, orgDetail } = useAppSelector((state) => state.referralProgram);
  const [input, setInput] = useState<string[]>([""]);
  const [errors, setErrors] = useState<string[]>([""]);
  const [urlLoading, setUrlLoading] = useState<boolean>(false);

  useEffect(() => {
    setUrlLoading(true);

    if (cognitoConfig?.currentOrganisation?.organisation_id) {
      dispatch(getOrgDetailAction(cognitoConfig?.currentOrganisation?.organisation_id));
    }
  }, [dispatch, cognitoConfig?.currentOrganisation?.organisation_id]);

  useEffect(() => {
    if (orgDetail && userInfo) {
      const typeString = `${orgDetail?.organisation?.type}_${userInfo?.role}`;

      const staticFileType =
        roleReferralProgram.find((role) => role.id === typeString)?.name ||
        roleReferralProgram.find((role) => role.id === userInfo?.role)?.name ||
        roleReferralProgram.find((role) => role.id === "DEFAULT")?.name;

      const selectedTemplate = staticFileTemplate.find((template) => template.file_type === staticFileType);

      if (selectedTemplate) {
        getSignedUrlFromS3(selectedTemplate?.bucket_name, `${selectedTemplate?.key}/${selectedTemplate?.file_name}`)
          .then((url: string) => {
            setPageUrl(url);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    setUrlLoading(false);
  }, [orgDetail, roleReferralProgram, staticFileTemplate, userInfo]);

  const handleClose = () => {
    setOpen(false);
    setInput([""]);
    setErrors([""]);
  };
  const handleOpen = () => setOpen(true);

  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...input];

    newInputs[index] = value;
    setInput(newInputs);
    const newErrors = [...errors];

    newErrors[index] = "";
    setErrors(newErrors);
  };

  const validateInput = (index: number, value: string) => {
    const newErrors = [...errors];
    let phoneNumber;

    switch (type) {
      case "email":
        newErrors[index] = emailValidation(value.trim().toLowerCase()) ? "" : "Invalid email";
        break;
      case "sms":
        phoneNumber = parsePhoneNumberFromString("+61" + value);
        newErrors[index] = phoneNumber?.isValid() ? "" : "Invalid number";
    }
    setErrors(newErrors);
  };

  const addEmailField = () => {
    if (input.length < 5) {
      setInput([...input, ""]);
      setErrors([...errors, ""]);
    }
  };

  const removeEmailField = (index: number) => {
    if (input.length > 1) {
      const newEmails = input.filter((_, i) => i !== index);
      const newErrors = errors.filter((_, i) => i !== index);

      setInput(newEmails);
      setErrors(newErrors);
    }
  };

  const sendInvitation = () => {
    let validInput;

    switch (type) {
      case "email":
        validInput = input.filter((v) => emailValidation(v.trim().toLowerCase()));

        if (validInput.length === 0) {
          setErrors(input.map((v) => (emailValidation(v.trim().toLowerCase()) ? "" : "Invalid email")));
          return;
        }
        break;
      case "sms":
        validInput = input.filter((v) => {
          const phoneNumber = parsePhoneNumberFromString("+61" + v.trim());

          return phoneNumber?.isValid();
        });

        if (validInput.length === 0) {
          setErrors(
            input.map((v) => {
              const phoneNumber = parsePhoneNumberFromString("+61" + v.trim());

              return phoneNumber?.isValid() ? "" : "Invalid number";
            })
          );
          return;
        }
    }

    const sendPromises = validInput.map((input) => {
      if (userInfo && userInfo?.email && cognitoConfig?.currentOrganisation) {
        const data: sendNotificationRequest = {
          variables: {
            user_name: userInfo?.fullName,
            user_email: userInfo?.email,
            user_id: userInfo?.id,
            organisation_name: cognitoConfig?.currentOrganisation?.organisation_name,
            organisation_id: cognitoConfig?.currentOrganisation?.organisation_id,
            display_id: userInfo?.displayId
          },
          targets: [input.trim().toLowerCase()],
          type: type,
          template: "referral"
        };

        return dispatch(sendReferralInvitationAction(data));
      }
    });

    Promise.all(sendPromises)
      .then((results) => {
        const allSuccessful = results.every((result) => result !== false);

        if (allSuccessful) {
          handleClose();
          immediateToast("success", {
            message: "Invitations sent successfully",
            timeout: 3000,
            position: "topCenter"
          });
        } else {
          console.error("Some invitations failed to send.");
        }
      })
      .catch((error) => {
        console.error("Error sending invitations:", error);
      });
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === "sendInvitationModal") {
        setOpen(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <Modalui
        open={open}
        handleClose={handleClose}
        backgroundColor="transparent"
        modalShadowOver={styles.modalShadow}
        modaluiOver={`${styles.modaluiOver} ${!open ? styles.closing : ""}`}
      >
        <form className={styles.inivitationContainer}>
          <header className={styles.headers}>
            <h1>Send Invitation</h1>
            <button onClick={handleClose} aria-label="Close modal">
              <CloseIcon onClick={handleClose} />
            </button>
          </header>
          <p className={styles.referralTile}>
            Referral Code: <span>{userInfo?.displayId}</span>
          </p>
          <body>
            {input.map((v, i) => (
              <div key={i} className={styles.inputField}>
                <div className={styles.input}>
                  {input.length > 1 && (
                    <div className={styles.removeBtn} onClick={() => removeEmailField(i)}>
                      -
                    </div>
                  )}
                  {type === "email" ? (
                    <input
                      value={v}
                      aria-label="inputField"
                      type="email"
                      placeholder="Enter email"
                      onBlur={() => validateInput(i, v)}
                      onChange={(e) => handleInputChange(i, e.target.value)}
                    />
                  ) : (
                    <div className={styles.mobileSelect}>
                      <Select
                        value={"+61"}
                        id="country-code-select"
                        displayEmpty
                        name="phoneCountryCode"
                        className={styles.countryCodeSelect}
                        sx={{ height: "50px" }}
                        disabled={true}
                        inputProps={{
                          "aria-label": "Select country code"
                        }}
                      >
                        {countryCodeData.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.code}
                          </MenuItem>
                        ))}
                      </Select>
                      <input
                        id="phone-input"
                        type="number"
                        placeholder="4745625522"
                        value={v}
                        name="phone"
                        onChange={(e) => handleInputChange(i, e.target.value)}
                        onBlur={() => validateInput(i, v)}
                      />
                    </div>
                  )}
                </div>
                {errors[i] && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {errors[i]}
                  </div>
                )}
              </div>
            ))}
          </body>
          <div className={styles.changeField}>
            <div
              className={styles.inputType}
              onClick={() => {
                setType(type === "email" ? "sms" : "email");

                setErrors([""]);
                setInput([""]);
              }}
            >
              {type === "email" ? "Use phone number instead" : "Use Email instead"}
            </div>
            {input.length < 5 && (
              <p onClick={addEmailField}>{type === "email" ? "Add another email" : "Add another number"}</p>
            )}
          </div>
          <Button
            aria-label="primary button"
            className={styles.sendBtn}
            variant="contained"
            onClick={sendInvitation}
            disabled={loading}
          >
            {loading ? <CircularProgress sx={{ color: "white" }} /> : "Send"}
          </Button>
        </form>
      </Modalui>
      <div className={styles.referralProgramContainer}>
        <Tooltip title="Send Invitation" arrow>
          <div className={`${styles.referralInvitation} ${open ? styles.closed : ""}`} onClick={handleOpen}>
            <div className={styles.pulseWave}></div>
            <PersonAddAltOutlinedIcon className={styles.actionIcon} />
          </div>
        </Tooltip>
        {urlLoading ? (
          <div className={styles.iframeSkeleton}>
            <Skeleton variant="rectangular" className={styles.imageSkeleton} />
            <div className={styles.textContainer}>
              <Skeleton variant="text" className={styles.textSkeleton} />
              <Skeleton className={styles.textSkeleton} variant="text" />
              <Skeleton className={styles.textSkeleton} variant="text" />
              <Skeleton className={styles.textSkeleton} variant="text" />
            </div>
          </div>
        ) : (
          <iframe title="Welcome template" src={pageUrl} style={{ width: "100%", height: "100vh", border: "none" }} />
        )}
      </div>
    </>
  );
}
