import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  UpdateWalletInputType,
  WalletPreferenceRequest,
  changeWalletPreference,
  connectCardToWallet,
  connectUserToWallet,
  getWalletCards,
  getWalletDetail,
  getWalletUsers,
  removeCardFromWallet,
  removeUserFromWallet,
  updateWallet
} from "src/network/graphql/walletService";
import { immediateToast } from "izitoast-react";
import { CardSearchRequest, cardSearch } from "src/network/graphql/cardService";
import { searchUser } from "src/network/graphql/userService";
import { Users } from "../users/usersSlice";
import { Card } from "../cards/cardSlice";
import { Status } from "src/types/commonTypes";
import { Transaction } from "../transactions/transactionSlice";
import { TransactionRequest, getAllTransaction, getMerchant } from "src/network/graphql/transactionService";

type Wallet = {
  id: number;
  participant: { fullName: string };
  description: string;
  balance: number;
};
const initialWallets: Wallet[] = [];

const initialTransaction: Transaction[] = [];

export type walletDetail = {
  wallet: {
    id: number;
    displayId: string;
    participant?: {
      id: number;
      displayId: string;
      fullName: string;
      firstName: string;
      lastname: string;
      email: string;
      phoneNumber: string;
      role: string;
      address: string;
      city: string;
      state: string;
      zipcode: string;
    };
    walletType: string;
    status: boolean;
    description: string;
    balance: number;
    name: string;
    defaultWallet: boolean;
    organisationId: number;
    repeatPay: boolean;
    repeatPayBalance: number;
    useWholeBalance: boolean;
    preferences: {
      cashWithdrawalAllowed: boolean;
      maxAllowedLimit: number;
      preferenceId: string;
      spendFrequency: string;
      spendLimit: number;
      blockedMerchants: string[];
      blockedMccCategories: string[];
    };
  };
  supporter: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
  }[];
  budgets: {
    id: number;
    itemCategory: { id: number; categoryName: string; isMasterItem: boolean };
    budgetAvailable: number;
  }[];
  pendingTransactions: {
    id: number;
    requestedAmount: number;
    isRepeatPay: boolean;
    useWholeBalance: boolean;
  }[];
  cards: {
    id: number;
    cardNumber: string;
    cardStatus: Status;
    accessType: string;
    accountNumber: string;
    user: {
      id: number;
      fullName: string;
      email: string;
      role: string;
      firstName: string;
      lastName: string;
    };
  }[];
};

export const initialWalletDetail: walletDetail = {
  wallet: {
    id: 0,
    displayId: "",
    participant: {
      id: 0,
      displayId: "",
      fullName: "",
      firstName: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      role: "",
      address: "",
      city: "",
      state: "",
      zipcode: ""
    },
    status: false,
    walletType: "",
    description: "",
    balance: 0,
    name: "",
    defaultWallet: false,
    organisationId: 0,
    repeatPay: false,
    repeatPayBalance: 0,
    useWholeBalance: false,
    preferences: {
      cashWithdrawalAllowed: false,
      maxAllowedLimit: 0,
      preferenceId: "",
      spendFrequency: "NONE",
      spendLimit: 0,
      blockedMerchants: [],
      blockedMccCategories: []
    }
  },
  supporter: [],
  budgets: [],
  pendingTransactions: [],
  cards: []
};

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];

const initialUsers: Users[] = [];

type budgetSupporter = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
};

const initialBudgetSupporter: budgetSupporter[] = [];

type masterList = {
  id: number;
  categoryName: string;
};

const initialMasterList: masterList[] = [];

const initialCards: Card[] = [];

type Merchant = {
  merchant_id: string;
  name: string;
  mcc: string;
  mcc_category: string;
  external_id: string;
};

const initialMerchant: Merchant[] = [];

const walletDetailSlice = createSlice({
  name: "walletDetail",
  initialState: {
    walletLoading: false,
    transLoading: false,
    walletDetailLoading: false,
    walletTransLoading: false,
    userLoading: false,
    budgetSupporterLoading: false,
    masterListLoading: false,
    deletebudgetLoading: false,
    updateBudgetLoading: false,
    repeatPayLoading: false,
    depositLoading: false,
    cardListLoading: false,
    cardConnectLoading: false,
    merchantLoading: false,
    error: null,
    WalletConnect: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletDetail: initialWalletDetail,
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    budgetSupporters: initialBudgetSupporter,
    mastersList: {
      total: 0,
      list: initialMasterList
    },
    depositList: {
      total: 0,
      list: initialTransaction
    },
    cards: {
      list: initialCards,
      total: 0
    },
    merchant: initialMerchant
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = action.payload?.list ?? [];
      state.WalletConnect.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = [];
      state.WalletConnect.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletDetailStart: (state) => {
      state.walletDetailLoading = true;
    },
    fetchWalletDetailSuccess: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail.wallet = action.payload.wallet ?? initialWalletDetail.wallet;
      state.walletDetail.supporter = action.payload.supporters ?? initialWalletDetail.supporter;
      state.walletDetail.budgets = action.payload.budgets ?? initialWalletDetail.budgets;
      state.walletDetail.pendingTransactions =
        action.payload.pendingTransactions ?? initialWalletDetail.pendingTransactions;
      state.walletDetail.cards = action.payload.cards ?? initialWalletDetail.cards;
    },
    fetchWalletDetailFail: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail = initialWalletDetail;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    deleteSupporterStart: (state) => {
      state.walletLoading = true;
    },
    deleteSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    deleteSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    addSupporterStart: (state) => {
      state.walletLoading = true;
    },
    addSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    addWalletStart: (state) => {
      state.walletDetailLoading = true;
    },
    addWalletSuccess: (state) => {
      state.walletDetailLoading = false;
      state.error = null;
    },
    addWalletFail: (state, action) => {
      state.walletDetailLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    updateWalletStart: (state) => {
      state.walletDetailLoading = true;
    },
    updateWalletSuccess: (state) => {
      state.walletDetailLoading = false;
    },
    updateWalletFail: (state, action) => {
      state.walletDetailLoading = false;
      state.error = action.payload;
    },
    getBudgetSupporterStart: (state) => {
      state.budgetSupporterLoading = true;
    },
    getBudgetSupporterSuccess: (state, action) => {
      state.budgetSupporterLoading = false;
      state.budgetSupporters = action?.payload ?? [];
    },
    getBudgetSupporterFail: (state, action) => {
      state.budgetSupporterLoading = false;
      state.budgetSupporters = [];
      state.error = action?.payload;
    },
    updateBudgetSupporterStart: (state) => {
      state.budgetSupporterLoading = true;
    },
    updateBudgetSupporterSuccess: (state) => {
      state.budgetSupporterLoading = false;
    },
    updateBudgetSupporterFail: (state, action) => {
      state.budgetSupporterLoading = false;
      state.error = action?.payload;
    },
    getMasterListStart: (state) => {
      state.masterListLoading = true;
    },
    getMasterListSuccess: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = action?.payload.total ?? 0;
      state.mastersList.list = action?.payload.list ?? [];
    },
    getMasterListFail: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = 0;
      state.mastersList.list = [];
      state.error = action?.payload;
    },
    deleteBudgetStart: (state) => {
      state.deletebudgetLoading = true;
    },
    deleteBudgetSuccess: (state) => {
      state.deletebudgetLoading = false;
      state.error = null;
    },
    deleteBudgetFail: (state, action) => {
      state.deletebudgetLoading = false;
      state.error = action?.payload;
    },
    updateBudgetStart: (state) => {
      state.updateBudgetLoading = true;
    },
    updateBudgetSuccess: (state) => {
      state.updateBudgetLoading = false;
      state.error = null;
    },
    updateBudgetFail: (state, action) => {
      state.updateBudgetLoading = false;
      state.error = action?.payload;
    },
    fetchRepeatPayStart: (state) => {
      state.repeatPayLoading = true;
    },
    fetchRepeatPaySuccess: (state) => {
      state.repeatPayLoading = false;
    },
    fetchRepeatPayFail: (state, action) => {
      state.repeatPayLoading = false;
      state.error = action?.payload;
    },
    fetchDepositListStart: (state) => {
      state.depositLoading = true;
    },
    fetchDepositListSuccess: (state, action) => {
      state.depositLoading = false;
      state.depositList.list = action.payload?.list ?? [];
      state.depositList.total = action.payload?.total ?? 0;
    },
    fetchDepositListFail: (state, action) => {
      state.depositLoading = false;
      state.depositList.list = [];
      state.depositList.total = 0;
      state.error = action.payload;
    },
    getAllCardsStart: (state) => {
      state.cardListLoading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.cardListLoading = false;
      state.cards.list = [];
      state.cards.total = 0;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.cardListLoading = false;
      state.cards.list = action.payload?.list ?? [];
      state.cards.total = action.payload?.total || 0;
    },
    addCardStart: (state) => {
      state.walletLoading = true;
    },
    addCardSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addCardFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    deleteCardStart: (state) => {
      state.walletLoading = true;
    },
    deleteCardSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    deleteCardFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    changePreferenceStart: (state) => {
      state.walletDetailLoading = true;
    },
    changePreferenceSuccess: (state) => {
      state.walletDetailLoading = false;
    },
    changePreferenceFail: (state, action) => {
      state.walletDetailLoading = false;
      state.error = action.payload;
    },
    getMerchantStart: (state) => {
      state.merchantLoading = true;
    },
    getMerchantSuccess: (state, action) => {
      state.merchantLoading = false;
      state.merchant = action.payload;
    },
    getMerchantFail: (state, action) => {
      state.merchantLoading = false;
      state.merchant = [];
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletDetailStart,
  fetchWalletDetailSuccess,
  fetchWalletDetailFail,
  deleteSupporterStart,
  deleteSupporterSuccess,
  deleteSupporterFail,
  addSupporterStart,
  addSupporterSuccess,
  addSupporterFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  updateWalletStart,
  updateWalletSuccess,
  updateWalletFail,
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  addCardStart,
  addCardSuccess,
  addCardFail,
  deleteCardStart,
  deleteCardSuccess,
  deleteCardFail,
  deleteBudgetSuccess,
  changePreferenceStart,
  changePreferenceSuccess,
  changePreferenceFail,
  fetchDepositListStart,
  fetchDepositListSuccess,
  fetchDepositListFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  getMerchantStart,
  getMerchantSuccess,
  getMerchantFail
} = walletDetailSlice.actions;

export const fetchWalletConnectAction = (status: boolean, walletId: number) => {
  return status && walletId;
};

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export const fetchWalletDetailAction = (data: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailStart());
    try {
      const walletResponse = await getWalletDetail(data);
      const wallet = walletResponse.data.get_wallet;
      const structuredWalletResponse = {
        name: wallet?.name,
        description: wallet?.description,
        balance: wallet?.balance,
        id: wallet?.wallet_id,
        displayId: wallet?.display_id,
        status: wallet?.wallet_status === "ACTIVE",
        walletType: wallet?.wallet_type,
        defaultWallet: wallet?.is_default,
        preferences: {
          cashWithdrawalAllowed: wallet?.preferences?.is_cash_withdrawal_allowed,
          maxAllowedLimit: wallet?.preferences?.maximum_allowed_limit,
          preferenceId: wallet?.preferences?.preference_id,
          spendFrequency: wallet?.preferences?.spend_frequency,
          spendLimit: wallet?.preferences?.spend_limit,
          blockedMerchants: wallet?.preferences?.blocked_merchant_ids,
          blockedMccCategories: wallet?.preferences?.blocked_mcc_categories
        },
        participant: {
          firstName: wallet?.user?.attributes?.first_name,
          lastname: wallet?.user?.attributes?.last_name,
          email: wallet?.user?.email,
          id: wallet?.user?.user_id,
          displayId: wallet?.user?.display_id
        }
      };

      const cardResponse = await getWalletCards(data);
      const structuredCardResponse = cardResponse.data.get_wallet_cards?.map((card: any) => ({
        id: card?.card_id,
        cardStatus: card?.card_status,
        cardNumber: card?.ppan,
        cardHolderName: card?.holder_name,
        user: {
          firstName: card?.user?.attributes?.first_name ?? "",
          lastName: card?.user?.attributes?.last_name ?? ""
        }
      }));

      const userResponse = await getWalletUsers(data);

      const structuredUserResponse = userResponse.data.get_wallet_users.map((user: any) => ({
        id: user?.user_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active
      }));

      dispatch(
        fetchWalletDetailSuccess({
          wallet: structuredWalletResponse,
          cards: structuredCardResponse,
          supporters: structuredUserResponse
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletDetailFail(error));
    }
  };
};

export const fetchWalletTransactionAction = (data: any) => {
  return data;
};

export const deleteWalletSupporterAction = (walletId: string, userId: string, removeFromAll: boolean) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteSupporterStart());
    try {
      await removeUserFromWallet(walletId, userId, removeFromAll);

      dispatch(deleteSupporterSuccess());
      immediateToast("success", {
        message: "User Removed Successfully",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(deleteSupporterFail(error));
    }
  };
};

export const addWalletSupporterAction = (walletId: string, userId: string, toAll: boolean = false) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addSupporterStart());
    try {
      await connectUserToWallet(walletId, userId, toAll);

      dispatch(addSupporterSuccess());
      immediateToast("success", {
        message: "Supporter Added Successfully.",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(addSupporterFail(error));
    }
  };
};

export const addWalletAction = (data: any, cb?: () => void) => {
  if (cb) cb();
  return data;
};

export const fetchUserListAction = (data: { limit: number; from: number }) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        // user_filter: {
        //   is_active: data.isActive,
        //   email: data.email,
        //   first_name: data.firstName,
        //   last_name: data.lastName
        // },
        size: data.limit,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user.display_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: "not available",
        status: user.is_active,
        userId: user.user_id
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const updateWalletAction = (data: UpdateWalletInputType) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateWalletStart());
    try {
      await updateWallet(data);

      dispatch(updateWalletSuccess());
    } catch (error) {
      dispatch(updateWalletFail(error));
    }
  };
};

export const getBudgetSupporterAction = (budgetId: number) => {
  return budgetId;
};

export const updateBudgetSupporterAction = (data: any, budgetId: number) => {
  return data.budget + budgetId;
};

export const getMasterListAction = () => {};

export const deleteBudgetAction = (budgetId: number) => {
  return (dispatch: StoreDispatch) => {
    dispatch(deleteBudgetSuccess());
    return Promise.resolve(budgetId);
  };
};
export const updateBudgetAction = (data: any, cb?: () => void) => {
  if (cb) cb();
  return data;
};

export const fetchRepeatPayAction = (data: any) => {
  return data;
};

export const fetchDepositListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchDepositListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        approval_number: data.approval_number,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchDepositListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchDepositListFail(err));
    }
  };
};

export const getAllCardsAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await cardSearch(data);
      const structuredResponse = response.data.search_card.card_listing?.map((card: any) => ({
        id: card.card_id,
        bsb: card.bsb,
        cardStatus: card.card_status,
        accessType: card.card_type,
        cardNumber: card.ppan,
        cardLimit: card.card_limit,
        user: {
          id: card.user_id,
          fullName: card.holder_name
        }
      }));

      dispatch(
        getAllCardsSuccess({
          list: structuredResponse,
          total: response.data.search_card.total_size
        })
      );
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};

export const addCardAction = (walletId: string, cardId: string, connectAll: boolean = false) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addCardStart());
    try {
      const response = await connectCardToWallet(walletId, cardId, connectAll);

      if (response?.data?.errors) {
        immediateToast("error", {
          message: response?.data?.errors[0]?.message,
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(addCardFail(response?.data?.errors[0]));
      } else {
        dispatch(addCardSuccess());
        return response;
      }
    } catch (error) {
      dispatch(addCardFail(error));
    }
  };
};

export const deleteWalletCardsAction = (walletId: string, cardId: string, removeFromAll: boolean = false) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteCardStart());
    try {
      const response = await removeCardFromWallet(walletId, cardId, removeFromAll);

      if (!response?.data?.errors) {
        dispatch(deleteCardSuccess());
        immediateToast("success", {
          message: "Card Removed Successfully",
          timeout: 3000,
          position: "topCenter"
        });
      } else {
        dispatch(deleteCardFail(response?.data?.errors[0]?.message));
        immediateToast("error", {
          message: response?.data?.errors[0]?.message,
          timeout: 3000,
          position: "topCenter"
        });
      }
    } catch (error) {
      dispatch(deleteCardFail(error));
    }
  };
};

export const changePreferenceAction = (data: WalletPreferenceRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changePreferenceStart());
    try {
      const response = await changeWalletPreference(data);

      dispatch(changePreferenceSuccess());
      return response;
    } catch (err) {
      dispatch(changePreferenceFail(err));
    }
  };
};

export const getMerchantAction = (merchantIds: string[]) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getMerchantStart());
    try {
      const response = await getMerchant(merchantIds);

      dispatch(getMerchantSuccess(response?.data?.get_merchants));
    } catch (error) {
      dispatch(getMerchantFail(error));
    }
  };
};

export default walletDetailSlice.reducer;
