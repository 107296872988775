import Modalui from "src/components/UI/modal/Modalui";
import styles from "./onBoarding.module.scss";
import { useState, useEffect, useCallback } from "react";
import { businessBuilding } from "src/components/nav/logo";
import poweredBy from "src/assets/images/poweredBy.png";
import VerticalTimeline from "src/components/UI/verticalTimeline/VerticalTimeline";
import AddOrgModal from "src/components/onBoardingModals/addOrg/AddOrgModal";
import { useAppDispatch, useAppSelector } from "../store";
import { useNavigate } from "react-router-dom";
import AddUserModal from "src/components/onBoardingModals/addUser/AddUserModal";
import { PARTICIPANT } from "src/components/constant/constant";
import AddWalletModal from "src/components/onBoardingModals/addWallet/AddWalletModal";
import OnBoardingSuccess from "src/components/onBoardingComponent/OnBoardingSuccessCard";
import OrganisationCreated from "src/assets/images/success1.png";
import WalletSuccess from "src/assets/images/success2.png";
import ParticipantSuccess from "src/assets/images/ParticipantSuccess.png";
import OnBoardComplete from "src/assets/images/CompleteOnboarding.png";
import { refreshSession } from "src/utils/utils";
import { TimelineSteps } from "./onBoardConfig";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { logout } from "../auth/loginSlice";
import { Tooltip } from "@mui/material";
import SubscriptionModal from "src/components/orgListCard/addOrgModal/SubscriptionModal";
import { addWalletAction, getSubscriptionAction } from "./onBoardingSlice";
import OrderCardModal from "src/components/onBoardingModals/orderCardModal/OrderCardModal";

export default function OnBoarding() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo, cognitoRefreshToken, cognitoAccessToken, cognitoConfig } = useAppSelector((state) => state.auth);
  const {
    userDetail,
    onboardingLoading,
    userLoading,
    walletLoading,
    onboardingProgress,
    clickCounterProgress,
    subscriptionLimits
  } = useAppSelector((state) => state.onBoarding);
  const [open, setOpen] = useState<boolean>(true);
  const [completeOnBoarding, setCompleteOnBoarding] = useState<boolean>(false);
  const [onBoarding, setOnBoarding] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [nextClickCounter, setNextClickCounter] = useState(0);
  const [currentNode, setCurrentNode] = useState(0);
  const [currentSubsection, setCurrentSubsection] = useState(0);
  const initialFillLevel =
    TimelineSteps[0]?.subsections?.length > 0 ? 100 / (TimelineSteps[0].subsections.length + 1) : 100;
  const [fillLevels, setFillLevels] = useState(
    new Array(TimelineSteps.length).fill(0).map((_, index) => (index === 0 ? initialFillLevel : 0))
  );
  const handleNextClick = () => {
    const subsections = TimelineSteps[currentNode]?.subsections || [];

    if (subsections.length > 0 && currentSubsection < subsections.length - 1) {
      setCurrentSubsection(currentSubsection + 1);
      const fillPercentage = ((currentSubsection + 2) / (subsections.length + 1)) * 100;

      setFillLevels((prev) => prev.map((level, index) => (index === currentNode ? fillPercentage : level)));
    } else {
      if (currentNode < TimelineSteps.length - 1) {
        setCurrentNode(currentNode + 1);
        setCurrentSubsection(-1);
        setFillLevels((prev) =>
          prev.map((level, index) => (index === currentNode ? 100 : index === currentNode + 1 ? level : level))
        );
      } else {
        setFillLevels((prev) => prev.map((level, index) => (index === currentNode ? 100 : level)));
      }
    }
  };

  useEffect(() => {
    if (cognitoAccessToken === null || cognitoAccessToken === undefined) {
      navigate("/sign-in", { replace: true });
    }
  }, [cognitoAccessToken, navigate]);

  useEffect(() => {
    if (onboardingProgress === "addParticipant") {
      setOpen(false);
      setOnBoarding(true);
      setSelectedOption("SubscriptionSuccess");
      setNextClickCounter(clickCounterProgress);
    }
  }, [onboardingProgress]);

  useEffect(() => {
    if (userInfo?.role) {
      setOpen(false);
      setOnBoarding(true);
      setSelectedOption("OrganisationSuccess");
      setNextClickCounter(1);
    }
  }, [userInfo?.role]);

  const getSubscriptionDetail = useCallback(
    (subscriptionId: string) => {
      dispatch(getSubscriptionAction(subscriptionId));
    },
    [dispatch]
  );

  /*
  useEffect(() => {
    if (subscriptionDetails) {
      setSelectedOption("SubcriptionSuccess");
      setNextClickCounter(3);
    }
  }, [subscriptionDetails]);
  */

  const switchForm = () => {
    if (selectedOption === "createOrganization") {
      setSelectedOption("OrganisationSuccess");
    } else if (selectedOption === "OrganisationSuccess") {
      setSelectedOption("SubscriptionModal");
    } else if (selectedOption === "SubscriptionModal") {
      setSelectedOption("SubscriptionSuccess");
    } else if (selectedOption === "SubscriptionSuccess") {
      setSelectedOption("addParticipant");
    } else if (selectedOption === "addParticipant") {
      setSelectedOption("ParticpantSuccess");
    } else if (selectedOption === "ParticpantSuccess") {
      setSelectedOption("addWallet");
    } else if (selectedOption === "addWallet") {
      setSelectedOption("WalletSuccess");
    } else if (selectedOption === "WalletSuccess") {
      setSelectedOption("OrderCard");
    }
  };

  const handleClose = () => {};

  const onOrgSuccess = () => {
    handleNextClick();
    switchForm();
    if (cognitoRefreshToken) {
      refreshSession(cognitoRefreshToken);
    }
  };

  const handleAddUser = () => {
    if (subscriptionLimits?.cashLimit === 0 && subscriptionLimits?.gneralLimit === 0) {
      setOnBoarding(false);
      setCompleteOnBoarding(true);
    } else {
      if (subscriptionLimits?.gneralLimit > 0) {
        handleNextClick();
        switchForm();
      } else if (subscriptionLimits?.cashLimit > 0) {
        dispatch(
          addWalletAction(
            {
              description: "A simple cash wallet for spending money",
              name: "Cash Wallet",
              userId: String(userDetail?.user?.id),
              walletType: "CASH"
            },
            () => {
              setOnBoarding(false);
              setCompleteOnBoarding(true);
            }
          )
        );
      }
    }
  };

  const handleAddWalletSuccess = () => {
    handleNextClick();
    switchForm();
  };

  const handleOrderCardSuccess = () => {
    setOnBoarding(false);
    setCompleteOnBoarding(true);
  };

  const handlePlanSelectSuccess = async () => {
    handleNextClick();
    switchForm();
    if (cognitoRefreshToken) {
      await refreshSession(cognitoRefreshToken);
    }
    if (cognitoConfig?.organisationSubscriptions) {
      getSubscriptionDetail(cognitoConfig?.organisationSubscriptions[0]);
    }
  };

  useEffect(() => {
    if (userInfo?.role && cognitoConfig?.organisationSubscriptions) {
      getSubscriptionDetail(cognitoConfig?.organisationSubscriptions[0]);
    }
  }, [cognitoConfig?.organisationSubscriptions, dispatch]);

  const renderForm = () => {
    switch (selectedOption) {
      case "createOrganization":
        return (
          <AddOrgModal
            handleClose={handleClose}
            onSuccess={onOrgSuccess}
            rootContainrOver={styles.addOrgOver}
            closeButton={false}
            orgLoading={onboardingLoading}
            buttonText="Next"
            Orgtype="CORPORATE"
          />
        );
      case "createParticipantOrganization":
        return (
          <AddOrgModal
            handleClose={handleClose}
            onSuccess={onOrgSuccess}
            rootContainrOver={styles.addOrgOver}
            closeButton={false}
            orgLoading={onboardingLoading}
            buttonText="Next"
            forParticipant
            Orgtype="INDIVIDUAL"
          />
        );
      case "OrganisationSuccess":
        return (
          <OnBoardingSuccess
            onNext={() => {
              switchForm();
              handleNextClick();
            }}
            onBack={() => {
              navigate("/");
            }}
            heading="You've created your Organisation!"
            subHeading="The next step is to select a subscription that works best for your organisation."
            buttonText={{ primaryButton: "Select Subscription" }}
            image={OrganisationCreated}
          />
        );
      case "SubscriptionModal":
        return <SubscriptionModal onSuccess={handlePlanSelectSuccess} />;
      case "SubscriptionSuccess":
        return (
          <OnBoardingSuccess
            onNext={() => {
              switchForm();
              handleNextClick();
            }}
            onBack={() => {
              navigate("/");
            }}
            heading="You've selected a Subscription!"
            subHeading="It’s time to add your first participant so that you never have to send another monthly statements or collect a box of receipts! Everyone will have access through their app."
            buttonText={{ primaryButton: "Add a Participant" }}
            image={OrganisationCreated}
          />
        );
      case "addParticipant":
        return (
          <AddUserModal
            onAddUserSuccess={handleAddUser}
            rootContainer={styles.addParticipantOver}
            role={PARTICIPANT}
            buttonText={{ primaryButton: "Next" }}
            userloading={userLoading}
          />
        );

      case "ParticpantSuccess":
        return (
          <OnBoardingSuccess
            onNext={() => {
              switchForm();
              handleNextClick();
            }}
            onBack={() => {
              navigate("/");
            }}
            heading="Your participant needs a wallet!"
            subHeading="Give your participant their first wallet so that they can keep their money there. The wallet allows you to deposit, withdraw and spend while keeping all of the evidence."
            buttonText={{ primaryButton: "Add a Wallet" }}
            image={ParticipantSuccess}
          />
        );

      case "addWallet":
        return (
          <AddWalletModal
            handleClose={handleClose}
            onAddWalletSuccess={handleAddWalletSuccess}
            rootContainer={styles.addWalletOver}
            closeButton={false}
            buttonText={{ primaryButton: "Create 1 Wallet" }}
            userId={String(userDetail?.user?.id)}
            walletloading={walletLoading}
            generalLimit={subscriptionLimits?.gneralLimit}
          />
        );

      case "WalletSuccess":
        return (
          <OnBoardingSuccess
            onNext={() => {
              switchForm();
              handleNextClick();
            }}
            onBack={() => {
              navigate("/");
            }}
            heading="You’re almost done!"
            subHeading="You need cards so that you can pay! Order a card for your participant that either they or the support workers will use."
            buttonText={{ primaryButton: "Order Card" }}
            image={WalletSuccess}
          />
        );
      case "OrderCard":
        return <OrderCardModal onSuccess={handleOrderCardSuccess} userData={userDetail} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (nextClickCounter > 0) {
      handleNextClick();
      setNextClickCounter(nextClickCounter - 1);
    }
  }, [nextClickCounter]);

  return (
    <div className={styles.onboarding}>
      {open && (
        <Modalui
          open={open}
          handleClose={handleClose}
          modaluiOver={styles.modaluiOver}
          modalShadowOver={styles.modalShadowOver}
          backgroundColor={"#00000000"}
        >
          <div className={styles.popUpLayout}>
            <p className={styles.textWrapper}>It is your first time here!</p>
            <p className={styles.areYouTheFirst}>
              Are you here to create an account for a service provider or for a participant directly? <br />
              <br />
              Select the best fit for you and we’ll guide you through the set up.
            </p>
            <div className={styles.selectButtons}>
              <div
                className={styles.divWrapper}
                onClick={() => {
                  setOpen(false);
                  setOnBoarding(true);
                  setSelectedOption("createOrganization");
                }}
              >
                <div className={styles.div}>
                  <div className={styles.rectangle} />
                  <div className={styles.textWrapper2}>Create your Organisation</div>
                  <div className={styles.businessBuilding}>{businessBuilding}</div>
                </div>
              </div>
              <div
                className={styles.divWrapper}
                onClick={() => {
                  setOpen(false);
                  setOnBoarding(true);

                  setSelectedOption("createParticipantOrganization");
                }}
              >
                <div className={styles.div}>
                  <div className={styles.rectangle} />
                  <p className={styles.textWrapper2}>Set up for an individual participant</p>
                  <div className={styles.businessBuilding}>{businessBuilding}</div>
                </div>
              </div>
            </div>
          </div>
        </Modalui>
      )}
      {onBoarding && (
        <div className={styles.onBoardingLayout}>
          <div className={styles.onBoardingNav}>
            <div className={styles.navContainer}>
              <div
                className={` ${styles.navBottomLogo} ${styles.sideOpt}`}
                style={{ cursor: userInfo?.role ? "pointer" : "default" }}
                onClick={() => {
                  if (userInfo?.role) {
                    navigate("/");
                  }
                }}
              >
                <img src={poweredBy} alt="logo" />
              </div>
              <div className={styles.onBoardingNavText}>Onboarding Progress</div>
              <div className={styles.verticalStpeer}>
                <VerticalTimeline
                  TimelineSteps={TimelineSteps}
                  currentNode={currentNode}
                  currentSubsection={currentSubsection}
                  fillLevels={fillLevels}
                />
              </div>
              <div className={styles.navigButton}>
                <Tooltip title="Sign Out">
                  <div
                    className={styles.logoutBtn}
                    onClick={() => {
                      dispatch(logout());
                      navigate("/sign-in");
                    }}
                  >
                    <ExitToAppOutlinedIcon />
                  </div>
                </Tooltip>
                {userInfo?.role && (
                  <Tooltip title="Dashboard">
                    <div
                      className={styles.homeBtn}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <HomeOutlinedIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className={styles.onBoardingContent}>
            <div className={styles.onBoardingForm}>{renderForm()}</div>
          </div>
        </div>
      )}
      {completeOnBoarding && (
        <Modalui
          open={completeOnBoarding}
          handleClose={handleClose}
          modaluiOver={styles.modaluiOver}
          modalShadowOver={styles.modalShadowOver}
          backgroundColor={"#00000000"}
        >
          <OnBoardingSuccess
            onNext={() => {
              navigate("/");
            }}
            onBack={() => {
              navigate("/");
            }}
            heading="Your Onboarding is Complete!"
            subHeading="You have successfully onboarded a new Participant"
            buttonText={{ primaryButton: "Go to Dashboard" }}
            image={OnBoardComplete}
            secondaryButton={false}
          />
        </Modalui>
      )}
    </div>
  );
}
