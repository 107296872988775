import { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./addWalletModal.module.scss";
import { useAppDispatch } from "src/page/store";
import { singleWallet, multipleWallet } from "src/components/nav/logo";
import { immediateToast } from "izitoast-react";
import { addWalletAction } from "src/page/onBoarding/onBoardingSlice";

interface AddUserModalProps {
  handleClose: () => void;
  onAddWalletSuccess: () => void;
  rootContainer?: string;
  closeButton?: boolean;
  heading?: string;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
  userId: string;
  walletloading?: boolean;
  generalLimit: number;
}
const budgetOptions = [
  "Spending Money",
  "Saving Money",
  "Clothes",
  "Groceries",
  "Eating Out / Takeaway",
  "Mobile Phone",
  "Medication",
  "Entertainment",
  "Bills",
  "Car",
  "Gifts",
  "Medical"
];

export default function AddUserModal({
  handleClose,
  onAddWalletSuccess,
  rootContainer,
  closeButton = true,
  heading = "Select the wallets you want in your budget",
  buttonText,
  userId,
  walletloading = false,
  generalLimit
}: AddUserModalProps) {
  const dispatch = useAppDispatch();
  const [isBudgetMode, setIsBudgetMode] = useState(false);
  const [selectedBudgets, setSelectedBudgets] = useState<string[]>([]);
  const [localLoading, setLocalLoading] = useState(false);

  const handleSingleWallet = () => {
    setLocalLoading(true);
    dispatch(
      addWalletAction(
        {
          description: "A simple wallet for spending money",
          name: "Spending Money",
          userId: userId,
          walletType: "GENERAL"
        },
        () => {
          setLocalLoading(false);
          onAddWalletSuccess();
        }
      )
    );
  };

  const handleCreateBudget = () => {
    setIsBudgetMode(true);
  };

  const toggleBudgetSelection = (walletName: string) => {
    if (selectedBudgets.length >= generalLimit && !selectedBudgets.includes(walletName)) {
      immediateToast("error", {
        message: `Your subscription only allows ${generalLimit} general wallets.`,
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }

    setSelectedBudgets((prev) =>
      prev.includes(walletName) ? prev.filter((w) => w !== walletName) : [...prev, walletName]
    );
  };

  const handleCreateSelectedBudgets = () => {
    if (selectedBudgets.length > generalLimit) {
      immediateToast("error", {
        message: `You can only create ${generalLimit} general wallets as per your subscription.`,
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }

    const wallets = selectedBudgets.map((name) => ({
      name,
      description: `Budget wallet for ${name}`,
      userId: userId,
      walletType: "GENERAL"
    }));

    setLocalLoading(true);

    let createdWalletsCount = 0;

    const checkCompletion = () => {
      createdWalletsCount++;
      if (createdWalletsCount === wallets.length) {
        setLocalLoading(false);
        onAddWalletSuccess();
      }
    };

    wallets.forEach((wallet) => dispatch(addWalletAction(wallet, checkCompletion)));
  };

  return (
    <div className={`${styles.modalContainer} ${rootContainer}`} aria-live="polite">
      <div className={styles.modalHeader}>
        <h5 style={{ fontSize: "20px" }}>{heading}</h5>
        {closeButton && (
          <button onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </button>
        )}
      </div>

      {walletloading || localLoading ? (
        <div className={styles.progress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
        </div>
      ) : !isBudgetMode ? (
        <>
          <p>You have 2 options for how to set up your wallets</p>
          <div className={styles.optionContainer}>
            <div className={styles.option}>
              <h6>
                Keep it simple with a single wallet called <strong>"Spending Money"</strong>
              </h6>
              <div className={styles.optionImage}>{singleWallet}</div>
              <Button variant="contained" onClick={handleSingleWallet} className={styles.optionButton}>
                {buttonText?.primaryButton || "Create 1 Wallet"}
              </Button>
            </div>
            <div className={styles.divider} />
            <div className={styles.option}>
              <h6>Create multiple wallets for your budget categories</h6>
              <div className={styles.optionImage}>{multipleWallet}</div>
              <Button variant="contained" onClick={handleCreateBudget} className={styles.optionButton}>
                {buttonText?.secondaryButton || "Create your budget"}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <p
            style={{ marginTop: "10px" }}
          >{`Select ${generalLimit > budgetOptions?.length ? "any" : generalLimit} amount of wallets that you want`}</p>
          <div className={styles.budgetOptions}>
            {budgetOptions.map((option) => (
              <div key={option} className={styles.budgetOption}>
                <Checkbox
                  checked={selectedBudgets.includes(option)}
                  onChange={() => toggleBudgetSelection(option)}
                  inputProps={{ "aria-label": option }}
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
          <div className={styles.budgetButtons}>
            <Button variant="outlined" onClick={() => setIsBudgetMode(false)} className={styles.optionBackButton}>
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleCreateSelectedBudgets}
              className={styles.optionCreateButton}
              disabled={selectedBudgets.length === 0}
            >
              Create Wallets
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
