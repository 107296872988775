/* eslint-disable no-nested-ternary */
import { useState, useEffect, ChangeEvent } from "react";
import styles from "./walletDetail.module.scss";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, Input, MenuItem, Select, SelectChangeEvent, Slider, TextField } from "@mui/material";
import Modalui from "src/components/UI/modal/Modalui";
import SupporterModal from "src/components/connectCard/supporterModal/SupporterModal";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchTransListAction,
  fetchWalletDetailAction,
  fetchWalletTransactionAction,
  addWalletSupporterAction,
  deleteWalletSupporterAction,
  addCardAction,
  updateWalletAction,
  deleteWalletCardsAction,
  changePreferenceAction,
  fetchDepositListAction
} from "./walletDetailSlice";
import { walletTransCardLogo, whiteCardLogo, whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import IOSSwitch from "src/components/SettingCard/IOSSwitch";
import { toggleDefaultWallet, toggleWalletActivation } from "src/network/graphql/walletService";
import { SpendFrequency } from "src/types/commonTypes";
import {
  CAN_CONNECT_CARD_TO_WALLET,
  CAN_CONNECT_USER_TO_WALLET,
  CAN_UPDATE_WALLET_SETTINGS,
  walletTypeToActivityName
} from "src/components/constant/constant";
import { immediateToast } from "izitoast-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { checkPermission, getDecimalNumber } from "src/utils/utils";
import SpendableVisaCard from "src/components/UI/spendableVisaCard/SpendableVisaCard";
import DepositCard from "src/components/depositCard/DepositeCard";
import BlockedWalletMerchant from "src/components/blockedWalletMerchant/BlockedWalletMerchant";
import { Item } from "../auth/loginSlice";
import AttachCard from "src/components/attachCard/AttachCard";

export default function WalletPage() {
  const { transList, walletDetail, walletTransaction, walletDetailLoading, depositList } = useAppSelector(
    (state) => state.walletDetail
  );
  const { mccCategory } = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [editWalletInfo, setEditWalletInfo] = useState<boolean>(false);
  const [cardModal, setCardModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { walletId } = useParams();

  const [isDefaultWallet, setIsDefaultWallet] = useState<boolean>(false);
  const [isActiveChecked, setIsActiveChecked] = useState<boolean>(false);
  const [spendFreq, setSpendFreq] = useState<SpendFrequency>("NONE");
  const [allowedLimit, setAllowedLimit] = useState<number>(0);
  const [spendLimit, setSpendLimit] = useState<number>(0);
  const [editPreference, setEditPreference] = useState<boolean>(false);
  const [cashWithdrwawal, setCashWithdrawal] = useState<boolean>(false);
  const [walletPreferenceError, setWalletPreferenceError] = useState("");
  const [blockedMccCategories, setBlockedMccCategories] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  console.log(mccCategory);

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletDetailAction(walletId));
    }
  }, [dispatch, walletId, isActiveChecked]);

  useEffect(() => {
    if (walletDetail) {
      setName(walletDetail?.wallet?.name);
      setDescription(walletDetail?.wallet?.description);
      setIsActiveChecked(walletDetail?.wallet?.status);
      setIsDefaultWallet(walletDetail?.wallet?.defaultWallet);
      setSpendFreq((walletDetail?.wallet?.preferences?.spendFrequency as SpendFrequency) || "NONE");
      setAllowedLimit(walletDetail?.wallet?.preferences?.maxAllowedLimit);
      setSpendLimit(walletDetail?.wallet?.preferences?.spendLimit);
      setCashWithdrawal(walletDetail?.wallet?.preferences?.cashWithdrawalAllowed || false);
      setBlockedMccCategories(walletDetail?.wallet?.preferences?.blockedMccCategories || []);
    }
  }, [walletDetail]);

  useEffect(() => {
    if (walletDetail) {
      dispatch(
        fetchTransListAction({
          size: 5,
          from: 0,
          filter: {
            activityName: walletTypeToActivityName[walletDetail?.wallet?.walletType],
            walletIds: [String(walletDetail?.wallet?.id)]
          }
        })
      );
      dispatch(
        fetchDepositListAction({
          size: 5,
          from: 0,
          filter: {
            transactionType: "DEPOSIT",
            walletIds: [String(walletDetail?.wallet?.id)]
          }
        })
      );
    }
  }, [searchText, receiptPresent, isReviewed, dispatch, walletDetail]);

  useEffect(() => {
    if (walletId) {
      fetchWalletTransactionAction({ walletIds: [Number(walletId)] });
    }
  }, [walletId]);

  const handleDefaultWalletToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsDefaultWallet(event.target.checked);
    if (walletId) {
      if (!isDefaultWallet) {
        if (!walletDetail?.wallet?.status) {
          setIsDefaultWallet(!event.target.checked);
          immediateToast("error", {
            message: "You cannot set an inactive wallet as the default",
            timeout: 3000,
            position: "topCenter"
          });
        } else {
          const response = await toggleDefaultWallet(walletId);

          if (response.data.errors) {
            setIsDefaultWallet(!event.target.checked);
          } else {
            setIsDefaultWallet(response.data.set_default_wallet.is_default ?? false);
          }
        }
      } else {
        setIsDefaultWallet(!event.target.checked);
        immediateToast("error", {
          message: "Can't disable default wallet ",
          timeout: 3000,
          position: "topCenter"
        });
      }
    }
  };

  const handleActiveToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsActiveChecked(event.target.checked);
    if (walletId) {
      const response = await toggleWalletActivation(walletId, event.target.checked);

      if (response.data.errors) {
        setIsActiveChecked(!event.target.checked);
      } else {
        setIsActiveChecked(response.data.toggle_wallet_activation.wallet_status === "ACTIVE");
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardModal = () => {
    setCardModal(!cardModal);
  };

  const handleAddSupporter = (email: string, userId: string, close: boolean, applyToAll: boolean) => {
    if (walletId) {
      dispatch(addWalletSupporterAction(walletId, userId, applyToAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });

      if (close) {
        handleClose();
      }
    }
  };

  const handleAddCard = (cardNumber: string, cardId: string, close: boolean, applyToAll: boolean) => {
    if (walletId) {
      dispatch(addCardAction(walletId, cardId, applyToAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });

      if (close) {
        setCardModal(false);
      }
    }
  };

  const handleDeleteSupporter = (email?: string, supporterId?: string, removeItFromAll?: boolean) => {
    if (walletId && supporterId) {
      dispatch(deleteWalletSupporterAction(walletId, supporterId, removeItFromAll || false)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });
    }
  };

  const handleDeleteCard = (cardNumber?: string, cardId?: string, removeItFromAll?: boolean) => {
    if (walletId && cardId) {
      dispatch(deleteWalletCardsAction(walletId, cardId, removeItFromAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });
    }
  };

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;

  const walletConnectHeading = "Users Connect";
  const deleteCardInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Wallet Card?"
  };

  const deleteSupporterInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Supporter?"
  };

  const handleWalletUpdate = () => {
    setEditWalletInfo(false);
    if (walletId) {
      dispatch(
        updateWalletAction({
          description,
          name,
          wallet_id: walletId
        })
      );
    }
  };

  const handleCashWithdrawalToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setCashWithdrawal(event.target.checked);
  };

  const handleSpenFreqChange = (event: SelectChangeEvent) => {
    setSpendFreq(event.target.value as SpendFrequency);
  };

  const handleAllowedLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllowedLimit(parseInt(event.target.value));
  };

  const handleSpendLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSpendLimit(parseInt(event.target.value));
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setAllowedLimit(newValue as number);
  };

  const handleSliderLimitChange = (event: Event, newValue: number | number[]) => {
    setSpendLimit(newValue as number);
  };

  const handleBlur = () => {
    if (allowedLimit < 0) {
      setAllowedLimit(0);
    } else if (allowedLimit > 1000) {
      setAllowedLimit(1000);
    }
  };

  const handleLimitBlur = () => {
    if (allowedLimit < 0) {
      setSpendLimit(0);
    } else if (allowedLimit > 1000) {
      setSpendLimit(1000);
    }
  };

  const handleWalletPreference = () => {
    if (walletId) {
      if (!allowedLimit || !spendLimit) {
        setWalletPreferenceError("Please set all spending limits before submitting.");
        return;
      }

      const data = {
        cashWithdrawalAllowed: cashWithdrwawal,
        maxAllowedLimit: allowedLimit,
        spendFrequency: spendFreq,
        spendLimit: spendLimit,
        blockedMccCategories: blockedMccCategories,
        walletId,
        blockedMerchantIds: walletDetail?.wallet?.preferences?.blockedMerchants ?? []
      };

      dispatch(changePreferenceAction(data)).then(() => {
        dispatch(fetchWalletDetailAction(walletId));
        setWalletPreferenceError("");
        setEditPreference(false);
      });
    }
  };

  const depositFields = (displayId: string) => [
    { key: "Account Name", value: "SpendAble" },
    { key: "BSB", value: "062 692" },
    { key: "Account #", value: "4531 7702" },
    { key: "Description", value: displayId }
  ];

  return (
    <>
      <Modalui open={open} handleClose={handleClose}>
        <SupporterModal
          handleClose={handleClose}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddSupporter}
        />
      </Modalui>
      <Modalui open={cardModal} handleClose={handleCardModal}>
        <AttachCard
          handleClose={handleCardModal}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddCard}
        />
      </Modalui>
      <div className={styles.walletPage} aria-label="Wallet Page">
        {walletDetailLoading ? <LoadingModal /> : ""}
        <div className={styles.walletPageWelCard}>
          {walletDetail && walletDetail?.wallet && (
            <PersonWelCard
              id={walletDetail.wallet.id}
              walletDetail={walletDetail}
              img={walletDetail.wallet?.name?.charAt(0).toLocaleUpperCase()}
              imgName={walletDetail?.wallet?.name}
              heading={walletDetail.wallet?.name}
              subHeading1={walletDetail.wallet.participant?.firstName + " " + walletDetail.wallet.participant?.lastname}
              subHeading2={walletDetail?.wallet?.participant?.email}
              connectedTo="Wallet"
            />
          )}
        </div>
        <div className={styles.userPageHead}>
          <div className={styles.infoCard}>
            <div className={styles.head}>
              <div className={styles.headL}>
                <h3>Wallet information</h3>
                <p>Edit the information about your wallet.</p>
              </div>
              {editWalletInfo ? (
                <Button
                  className={styles.saveBtn}
                  variant="contained"
                  onClick={handleWalletUpdate}
                  aria-label="Save Wallet Information"
                >
                  Save
                </Button>
              ) : (
                <div className={styles.headR}>
                  <div className={styles.editIconContainer} onClick={() => setEditWalletInfo(true)}>
                    <EditIcon className={styles.editIcon} aria-label="Edit Wallet Information" />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.walletInfo}>
              <div className={styles.label}>
                <label htmlFor="name">Name :</label>
                <span className={styles.value}>
                  {editWalletInfo ? (
                    <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                  ) : (
                    name
                  )}
                </span>
              </div>
              <div className={styles.label}>
                <label htmlFor="description">Description :</label>
                <span className={styles.value}>
                  {editWalletInfo ? (
                    <input
                      id="description"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  ) : (
                    description
                  )}
                </span>
              </div>
              <div className={styles.label}>
                <label>Balance :</label>
                <span className={styles.value}>${getDecimalNumber(walletDetail?.wallet?.balance)}</span>
              </div>
              <div className={styles.label}>
                <label> Active :</label>
                <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={<IOSSwitch sx={{ m: 1 }} checked={isActiveChecked} onChange={handleActiveToggle} />}
                    label=""
                    aria-label="active"
                  />
                </FormGroup>
              </div>
              <div className={styles.label}>
                <label> Default :</label>
                <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={<IOSSwitch sx={{ m: 1 }} checked={isDefaultWallet} onChange={handleDefaultWalletToggle} />}
                    label=""
                    aria-label="default"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          {["CASH"].includes(walletDetail?.wallet?.walletType) ? null : (
            <div className={styles.preferenceCard}>
              <div className={styles.head}>
                <div className={styles.headL}>
                  <h3>Wallet preference</h3>
                  <p>Edit the prefernce fo your wallet.</p>
                </div>
                <div className={styles.headR}>
                  {editPreference ? (
                    <Button className={styles.saveBtn} variant="contained" onClick={handleWalletPreference}>
                      Save
                    </Button>
                  ) : (
                    <div className={styles.editIconContainer}>
                      {checkPermission(CAN_UPDATE_WALLET_SETTINGS) && (
                        <EditIcon
                          className={styles.editIcon}
                          onClick={() => {
                            setEditPreference(true);
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.preferenceInfo}>
                <div className={styles.label}>
                  <label>Cash Withdrawal :</label>
                  <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                    <FormControlLabel
                      disabled={!editPreference}
                      className={styles.formControlLabel}
                      control={
                        <IOSSwitch sx={{ m: 1 }} checked={cashWithdrwawal} onChange={handleCashWithdrawalToggle} />
                      }
                      label=""
                    />
                  </FormGroup>
                </div>
                <div className={styles.label}>
                  <label>I want to set a limit for the:</label>
                  {editPreference ? (
                    <Select
                      value={spendFreq}
                      onChange={handleSpenFreqChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        height: 40,
                        color: {
                          //DAILY: ""
                          WEEKLY: "",
                          BIWEEKLY: "",
                          MONTHLY: "",
                          NONE: "grey",
                          default: "grey"
                        }[spendFreq]
                      }}
                    >
                      <MenuItem value="NONE" sx={{ color: "grey" }}>
                        None
                      </MenuItem>
                      <MenuItem value="WEEKLY">Week</MenuItem>
                      <MenuItem value="BIWEEKLY">Fortnight</MenuItem>
                      <MenuItem value="MONTHLY">Month</MenuItem>
                    </Select>
                  ) : (
                    <span className={styles.value}>{spendFreq}</span>
                  )}
                </div>
                <div className={styles.allowedLimit}>
                  <div className={`${styles.label} ${editPreference ? styles.sliderInput : ""}`}>
                    <label>The amount of the limit is:</label>
                    {editPreference ? (
                      <Input
                        type="number"
                        value={allowedLimit}
                        onChange={handleAllowedLimitChange}
                        onBlur={handleBlur}
                        inputProps={{ min: 0, max: 1000 }}
                        aria-labelledby="input-slider"
                      />
                    ) : (
                      <span className={styles.value}>{allowedLimit}</span>
                    )}
                  </div>
                  {editPreference && (
                    <div className={styles.slider}>
                      <Slider
                        value={typeof allowedLimit === "number" ? allowedLimit : 0}
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        aria-label="custom thumb label"
                        sx={{ color: "#1e293b" }}
                      />
                      <div className={styles.maxmin}>
                        <span>
                          0 <em> (Min)</em>
                        </span>
                        <span>
                          1000 <em> (Max)</em>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.spendLimit}>
                  <div className={`${styles.label} ${editPreference ? styles.sliderInput : ""}`}>
                    <label>I want to limit the card to purchases below:</label>
                    {editPreference ? (
                      <Input
                        type="number"
                        value={spendLimit}
                        onChange={handleSpendLimitChange}
                        onBlur={handleLimitBlur}
                        inputProps={{ min: 0, max: 1000 }}
                        aria-labelledby="input-slider"
                      />
                    ) : (
                      <span className={styles.value}>{spendLimit}</span>
                    )}
                  </div>
                  {editPreference && (
                    <div className={styles.slider}>
                      <Slider
                        value={typeof spendLimit === "number" ? spendLimit : 0}
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        onChange={handleSliderLimitChange}
                        aria-labelledby="input-slider"
                        aria-label="custom thumb label"
                        sx={{ color: "#1e293b" }}
                      />
                      <div className={styles.maxmin}>
                        <span>
                          0 <em> (Min)</em>
                        </span>
                        <span>
                          1000 <em> (Max)</em>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.mccCategory}>
                  <label>Blocked Merchant Categories :</label>
                  {editPreference ? (
                    <Autocomplete
                      multiple
                      sx={{ flex: 1 }}
                      options={mccCategory ?? []}
                      getOptionLabel={(option) => option?.name || ""}
                      disableCloseOnSelect
                      value={
                        blockedMccCategories
                          .map((id) => mccCategory.find((cat) => cat.id === id))
                          .filter(Boolean) as Item[]
                      }
                      onChange={(event, newValue) => {
                        setBlockedMccCategories(newValue.map((item) => item.id));
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Select MCC Categories" />
                      )}
                      ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                    />
                  ) : blockedMccCategories.length > 0 ? (
                    <span className={styles.ellipsis}>
                      {blockedMccCategories
                        .map((id) => id.charAt(0).toUpperCase() + id.slice(1).toLowerCase())
                        .join(", ")}
                    </span>
                  ) : (
                    "None"
                  )}
                </div>
                {walletPreferenceError && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {walletPreferenceError}
                  </div>
                )}
              </div>
            </div>
          )}
          {["DIGITAL", "CASH"].includes(walletDetail?.wallet?.walletType) ? null : (
            <div className={styles.settingCard}>
              <h1>Deposit to this Wallet</h1>
              <p>Use your bank app to transfer funds using these details</p>
              <div className={styles.depositDetails}>
                {depositFields(walletDetail?.wallet?.displayId).map((field) => (
                  <div key={field.key} className={styles.fRow}>
                    <div className={styles.item}>{field.key}</div>
                    <div className={styles.item}>:</div>
                    <div className={styles.item}>
                      <strong>{field.value}</strong>
                    </div>
                  </div>
                ))}
              </div>
              <p>*Deposits can take 1-2 business days to process</p>
            </div>
          )}
          {walletDetail?.wallet?.walletType === "CASH" && (
            <div className={styles.connectleftCard}>
              <ConnectCard
                walletUsers={walletDetail.supporter}
                heading={walletConnectHeading}
                onDeleteSupporter={handleDeleteSupporter}
                connectedTo={"Wallet"}
                deleteInput={deleteSupporterInput}
              >
                {checkPermission(CAN_CONNECT_USER_TO_WALLET) && (
                  <AddIcon className={styles.editIcon} onClick={handleOpen} />
                )}
              </ConnectCard>
            </div>
          )}
        </div>
        {walletDetail?.wallet?.walletType !== "CASH" && (
          <div className={styles.userPageMid}>
            {["DIGITAL"].includes(walletDetail?.wallet?.walletType) ? (
              <>
                <div className={styles.settingCard}>
                  <h1>Deposit to this Wallet</h1>
                  <p>Use your bank app to transfer funds using these details</p>
                  <div className={styles.depositDetails}>
                    {depositFields(walletDetail?.wallet?.displayId).map((field) => (
                      <div key={field.key} className={styles.fRow}>
                        <div className={styles.item}>{field.key}</div>
                        <div className={styles.item}>:</div>
                        <div className={styles.item}>
                          <strong>{field.value}</strong>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p>*Deposits can take 1-2 business days to process</p>
                </div>
                <div className={styles.visaCardContainer}>
                  <h1>Your Virtual SpendAble Card</h1>
                  <p>Tap and check the card detail of this virtual card</p>
                  <div className={styles.card}>
                    <SpendableVisaCard />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.connectleftCard}>
                  <ConnectCard
                    walletUsers={walletDetail.supporter}
                    heading={walletConnectHeading}
                    onDeleteSupporter={handleDeleteSupporter}
                    connectedTo={"Wallet"}
                    deleteInput={deleteSupporterInput}
                  >
                    {checkPermission(CAN_CONNECT_USER_TO_WALLET) && (
                      <AddIcon className={styles.editIcon} onClick={handleOpen} />
                    )}
                  </ConnectCard>
                </div>
                <div className={styles.connectCard}>
                  <ConnectCard
                    walletUsers={walletDetail.cards}
                    heading={"Card Connect"}
                    onDeleteSupporter={handleDeleteCard}
                    connectedTo={"WalletCards"}
                    deleteInput={deleteCardInput}
                  >
                    {checkPermission(CAN_CONNECT_CARD_TO_WALLET) && (
                      <AddIcon className={styles.editIcon} onClick={handleCardModal} />
                    )}
                  </ConnectCard>
                </div>
                <div className={styles.blockedMerchant}>
                  <BlockedWalletMerchant data={walletDetail} />
                </div>
              </>
            )}

            {walletTransaction?.length > 0 && (
              <div className={styles.transCard}>
                {Object.entries(walletTransaction).map(([key, transItem]) => {
                  let title;
                  let icon;

                  if (key === "totalWalletBalance") {
                    title = "Wallet Balance";
                    icon = walletTransCardLogo;
                  } else if (key === "totalCardBalance") {
                    title = "Card Balance";
                    icon = whiteCardLogo;
                  } else if (key === "totalTransactionsWithoutReceipts") {
                    title = "Transactions without receipt (30 days)";
                    icon = receiptLogo;
                  } else if (key === "totalTransactions") {
                    title = "Transactions (30 Days)";
                    icon = whiteFourPillarLogo;
                  }

                  return (
                    [
                      "totalWalletBalance",
                      "totalCardBalance",
                      "totalTransactionsWithoutReceipts",
                      "totalTransactions"
                    ].includes(key) &&
                    transItem && (
                      <WalletTransCard
                        key={transItem.id}
                        icon={icon}
                        title={title}
                        value={
                          title && ["Wallet Balance", "Card Balance"].includes(title)
                            ? `$${Number(transItem.current).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`
                            : transItem.current
                        }
                        growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                        growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                        from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                        WalletTransCardClass={WalletTransCardOver}
                        balCardClass={balCardOver}
                      />
                    )
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className={styles.userPageBottom}>
          <RecentTrans
            transList={transList.trans}
            setSearchText={setSearchText}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            walletId={String(walletId)}
            role={"WalletId"}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made by this wallet"}
          />
          <DepositCard depositList={depositList?.list} walletId={walletId} recentTransOver={styles.recentTransOver} />
        </div>
      </div>
    </>
  );
}
