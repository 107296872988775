const mccColumns = [
  {
    title: "MCC ID",
    dataIndex: "mcc_id",
    key: "mcc_id"
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "MCC Code",
    dataIndex: "mcc",
    key: "mcc"
  },
  {
    title: "Category",
    dataIndex: "mcc_category",
    key: "mcc_category"
  }
];

export default mccColumns;
