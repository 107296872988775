import { CognitoRefreshToken, CognitoUserSession } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import cognitoPool from "src/components/cognitoAuth/cognitoPool";
import {
  cognitoLoginSuccess,
  setCognitoConfig,
  setUpgradePlanFlag,
  setUserRole,
  upgradePlanAction
} from "src/page/auth/loginSlice";
import store from "src/page/store";

import config, { HostPrefix } from "src/config/Config";
import { immediateToast } from "izitoast-react";
import { Transaction } from "src/page/transactions/transactionSlice";
import { Settings } from "luxon";
import moment from "moment";
import axios from "axios";
import { upgradePlanRequest } from "src/network/graphql/configService";
import swal from "sweetalert2";
import { checkUserNameAvailability } from "src/network/graphql/userService";

export const getOrdinalSuffix = (number: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100;

  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};

export const isHostPrefixPresent = (hostPrefix: HostPrefix) => {
  const hostname = window.location.hostname;

  return hostname.includes(hostPrefix);
};

export const getTimeZone = () => {
  const userTimeZone = Settings.defaultZone;

  return userTimeZone;
};

export const dateTimeFormat = (data: string | number | null) => {
  if (data === null) {
    return {
      date: "----",
      time: "----",
      datetime: "----",
      dwml: "----"
    };
  }

  const DateTime = moment(data);

  return {
    date: DateTime.format("DD/MM/YYYY"),
    time: DateTime.format("hh:mm A"),
    datetime: DateTime.format("DD/MM/YYYY hh:mm A"),
    dwml: DateTime.format("DD MMM YYYY")
  };
};

export const formatTimestamp = (timestamp: string | undefined): string | undefined => {
  if (timestamp && typeof timestamp === "string") {
    return timestamp.replace(" ", "T") + "Z";
  }
  return undefined;
};

export const processFileName = (fileName: string, transactionId: string) => {
  const { auth } = store.getState();
  const currentDate = Math.floor(Date.now() / 1000);
  const organisationId = String(auth?.cognitoConfig?.currentOrganisation?.organisation_id);
  const lastDotIndex = fileName.lastIndexOf(".");
  const name = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1);

  const formattedName = name.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();

  const keyName = `${organisationId}/transaction/${transactionId}/${formattedName}_${currentDate}.${extension}`;

  return keyName;
};

export const getDecimalNumber = (amount: number) => {
  return Math.abs(parseFloat(amount?.toFixed(3)));
};

export const formatInitial = (initial: string | undefined | null): string => {
  if (!initial) return "Mr.";
  return initial.endsWith(".") ? initial : `${initial}.`;
};

const REGION = config.REGION;

AWS.config.update({
  region: config.REGION
});

interface uploadFileToS3Prop {
  file: File;
  fileName: string;
  S3Bucket: string;
  MetaData?: {
    object_id?: string;
    object_type?: string;
    asset_type?: string;
    file_type?: string;
    date?: string;
  };
  contentType?: string;
}

export const uploadFileToS3 = async (uploadData: uploadFileToS3Prop): Promise<AWS.S3.ManagedUpload.SendData> => {
  const params = {
    ContentDisposition: "inline",
    Body: uploadData.file,
    Bucket: uploadData.S3Bucket,
    Key: uploadData.fileName,
    Metadata: uploadData.MetaData,
    ...(uploadData?.contentType && { ContentType: uploadData.contentType })
  };

  const cognitoUser = cognitoPool.getCurrentUser();

  if (cognitoUser != null) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession(function (err: Error, session: CognitoUserSession | null) {
        if (err || session === null) {
          reject(err.message || JSON.stringify(err));
          return;
        }

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: config.IDENTITY_POOL_ID,
          Logins: {
            [`cognito-idp.${config.REGION}.amazonaws.com/${config.COGNITO_USER_POOL_ID}`]: session
              .getIdToken()
              .getJwtToken()
          }
        });

        const s3 = new AWS.S3({
          params: { Bucket: uploadData.S3Bucket },
          region: REGION
        });

        s3.putObject(params)
          .on("httpUploadProgress", (evt) => {
            if (process.env.REACT_APP_BUILD_ENV !== "production") {
              console.log(`Upload Progress: ${Math.round((evt.loaded / evt.total) * 100)}%`);
            }
          })
          .send((err, data) => {
            if (err) {
              reject(err);
              immediateToast("error", {
                message: `Failed to upload ${uploadData.file.name}`,
                timeout: 3000,
                position: "topCenter"
              });
            } else {
              resolve(data as AWS.S3.ManagedUpload.SendData);
              immediateToast("success", {
                message: `${uploadData.file.name} uploaded successfully`,
                timeout: 3000,
                position: "topCenter"
              });
            }
          });
      });
    });
  } else {
    return Promise.reject(new Error("Cognito user is null"));
  }
};

export const getSignedUrlFromS3 = async (bucketName: string, key: string, download: boolean = false) => {
  if (!key) {
    throw new Error("Key parameter is required for S3 operations");
  }
  const params = {
    Bucket: bucketName,
    Key: key,
    ...(download && { ResponseContentDisposition: "attachment" })
  };

  const cognitoUser = cognitoPool.getCurrentUser();

  if (cognitoUser != null) {
    return new Promise<string>((resolve) => {
      cognitoUser.getSession(async function (err: Error, session: CognitoUserSession | null) {
        if (err || session === null) {
          resolve("");
          return;
        }

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: config.IDENTITY_POOL_ID,
          Logins: {
            [`cognito-idp.${config.REGION}.amazonaws.com/${config.COGNITO_USER_POOL_ID}`]: session
              .getIdToken()
              .getJwtToken()
          }
        });

        const s3 = new AWS.S3({
          params: { Bucket: config.S3_ASSET_BUCKET },
          region: REGION
        });

        resolve(s3.getSignedUrlPromise("getObject", params));
      });
    });
  }
  return Promise.resolve("");
};

export const refreshSession = (refreshToken: string) => {
  const dispatch = store.dispatch;
  const cognitoUser = cognitoPool.getCurrentUser();
  dispatch(setUpgradePlanFlag(false));

  if (cognitoUser === null) {
    return new Promise((resolve, reject) => {
      const response = axios.post(
        `${config.cognitoConfig.baseUrl}/oauth2/token`,
        new URLSearchParams({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: config.COGNITO_CLIENT_ID
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      if (response === null || response === undefined) return reject({ message: "User session expired" });

      response
        .then((res) => {
          const accessToken = res.data.access_token;
          const idToken = res.data.id_token;

          const payload = JSON.parse(atob(idToken.split(".")[1]));

          dispatch(setUserRole(payload.current_role));
          const organisations = JSON.parse(payload.available_relations);

          const currentOrganisation = organisations.find(
            (org: { organisation_id: string }) => org.organisation_id === payload.current_organisation
          );

          dispatch(
            setCognitoConfig({
              organisations,
              currentOrganisation: currentOrganisation
            })
          );

          const structuredSession = {
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken
          };

          dispatch(cognitoLoginSuccess(structuredSession));
          return resolve(structuredSession);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        new CognitoRefreshToken({
          RefreshToken: refreshToken
        }),
        (err, session: CognitoUserSession) => {
          if (err) {
            return reject(err);
          } else {
            const accessToken = session.getAccessToken();
            const payload = accessToken.payload;

            if (payload.current_role) {
              dispatch(setUserRole(payload.current_role));
              const organisations = JSON.parse(payload.available_relations);

              const currentOrganisation = organisations.find(
                (org: { organisation_id: string }) => org.organisation_id === payload.current_organisation
              );

              dispatch(
                setCognitoConfig({
                  organisations,
                  currentOrganisation: currentOrganisation
                })
              );
            }

            const structuredSession = {
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken()
            };

            dispatch(cognitoLoginSuccess(structuredSession));
            return resolve(structuredSession);
          }
        }
      );
    });
  }
};

export const createUsername = (input: { firstName: string; lastName: string }) => {
  const random = Math.floor(100000000 + Math.random() * 900000000);

  return `${input.firstName}_${input.lastName}_${random}`.replace(/\s+/g, "_").toLowerCase();
};

export const checkPermission = (permisssion: string) => {
  const { auth } = store.getState();

  return auth.cognitoConfig?.allowedPermissions?.includes(permisssion);
};

export const getCurrentRole = (roles: { organisation_id: string; value: string }[] | null) => {
  if (roles === null) return { value: "" };
  const { auth } = store.getState();

  return roles.find((role) => role.organisation_id === auth.cognitoConfig.currentOrganisation?.organisation_id);
};

export const removeNull = (obj: object) => {
  const newObj: { [_key: string]: any } = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value != null || value != undefined) {
      newObj[key] = value;
    }
  });
  return newObj;
};

export const getTransactionValue = (transaction: Transaction) => {
  const amount = "$" + getDecimalNumber(transaction?.data?.amount);
  let value;
  let amountColor;

  if (transaction?.name === "NOVATTI_CARD_TRANSACTION") {
    if (transaction?.data?.transType === "CASH") {
      value = transaction?.data?.description;
    } else {
      value = transaction?.data?.merchantName;
    }
    amountColor = "red";
  } else if (transaction?.name === "WALLET_TRANSACTION") {
    if (transaction?.data?.type === "DEPOSIT") {
      value = "Deposit Spendable";
      if (transaction?.data?.amount > 0) {
        amountColor = "green";
      } else {
        amountColor = "red";
      }
    } else if (transaction?.data?.type === "TRANSFER") {
      if (transaction?.data?.side === "OUT") {
        value = transaction?.data?.destination_wallet?.name;
        amountColor = "red";
      } else if (transaction?.data?.side === "IN") {
        value = transaction?.data?.source_wallet?.name;
        amountColor = "green";
      }
    } else if (transaction?.data?.type === "ADJUSTMENT") {
      value = "Adjustment SpendAble";
      if (transaction?.data?.amount > 0) {
        amountColor = "green";
      } else {
        amountColor = "red";
      }
    }
  } else if (transaction?.name === "CASH_TRANSACTION") {
    value = transaction?.data?.merchantName;
    amountColor = "red";
  }
  return {
    value,
    amount,
    amountColor
  };
};

export const userNameValidation = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!input) {
    return "Username is required";
  }

  if (emailRegex.test(input)) {
    return "The username cannot be an email address.";
  } else {
    return "";
  }
};

export const emailValidation = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailRegex.test(input);
};

interface UpgradePlanProp {
  title: string;
  subTitle: string;
}

export const upgradePlan = ({ title, subTitle }: UpgradePlanProp) => {
  const dispatch = store.dispatch;
  const { auth } = store.getState();

  const upgradePlanData: upgradePlanRequest = {
    user_name: String(auth?.userInfo?.fullName),
    user_email: String(auth?.userInfo?.email),
    user_id: String(auth?.userInfo?.id),
    organisation_name: String(auth?.cognitoConfig?.currentOrganisation?.organisation_name),
    organisation_id: String(auth?.cognitoConfig?.currentOrganisation?.organisation_id)
  };

  swal
    .fire({
      icon: "warning",
      title: title,
      text: subTitle,
      showCancelButton: true,
      confirmButtonText: "Upgrade Plan",
      cancelButtonText: `I'll do it later.`,
      confirmButtonColor: "#0f172a"
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(
          upgradePlanAction(upgradePlanData, () => {
            swal.fire({
              icon: "success",
              title: "Thank you! Our team will contact you shortly to assist with upgrading your plan.",
              showConfirmButton: false,
              timer: 3000
            });
          })
        );
      }
    });
};

export const generateUsername = async (firstName: string, lastName: string): Promise<string> => {
  let attempts = 0;
  let generatedUsername = "";

  do {
    const randomNumber = Math.floor(Math.random() * 900) + 100;

    generatedUsername = `${firstName}.${lastName}${randomNumber}`.toLowerCase();

    const isUserNameAvailable = await checkUserNameAvailability(generatedUsername);
    if (isUserNameAvailable.data.is_username_available) {
      return generatedUsername;
    }

    attempts++;
  } while (attempts < 3);

  return "";
};
