import { Dispatch, createSlice } from "@reduxjs/toolkit";
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import cognitoPool from "src/components/cognitoAuth/cognitoPool";
import { LoginData, User } from "src/types/commonTypes";
import swal from "sweetalert2";
import type { Organisation as CommonOrgType, SignUpData } from "src/types/commonTypes";
import store, { StoreDispatch } from "../store";
import { getUserById } from "src/network/graphql/userService";
import {
  SwitchOrganisationRequest,
  getUiConfig,
  switchOrganisation,
  upgradePlan,
  upgradePlanRequest
} from "src/network/graphql/configService";
import { immediateToast } from "izitoast-react";
import { setSetPasswordLoading, setShowSetPassword } from "../users/usersSlice";
import config from "src/config/Config";
import axios from "axios";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { listSubscription } from "src/network/graphql/subscriptionServices";
import { SubsList } from "../plans/plansSlice";

// import { useNavigate } from "react-router-dom";

export type Organisation = {
  id: string;
  name: string;
};

export type Config = null | {
  rolesCanBeAdded: string[];
  organisations: Organisation[];
  currentOrganisation?: Organisation;
  allowedPermissions: string[];
};

export type CognitoConfig = {
  organisations?: CommonOrgType[];
  currentOrganisation?: CommonOrgType;
  allowedPermissions?: string[];
  rolesAllowedToAdd?: string[];
  defaultOrgId?: string;
  params?: {
    switch_organisation_option?: string;
    payment_template?: string;
  };
  organisationSubscriptions?: string[];
};

export type Item = {
  id: string;
  name: string;
  highlight?: boolean;
};

export type StaticFileTemplate = {
  bucket_name: string;
  key: string;
  file_type: string;
  file_name: string;
  name: string;
};

export type FileTemplate = {
  bucket_name: string;
  key: string;
  file_type: string;
};

const initialState: {
  switchOrgLoading: boolean;
  loading: boolean;
  changePasswordPermission: boolean;
  showSettingOrg: boolean;
  adminPortal: boolean;
  upgradePlanLoading: boolean;
  OrgSubscriptionLoading: boolean;
  upgradePlanFlag: boolean;
  userInfo: User | null;
  cognitoIdToken: string | null;
  cognitoAccessToken: string | null;
  cognitoRefreshToken: string | null;
  token: string | null;
  error: object | null;
  forgetPasswordLoading: boolean;
  resetValidationLoading: boolean;
  resetPasswordLoading: boolean;
  changePasswordLoading: boolean;
  resetForgetPasswordLoading: boolean;
  config: Config;
  menuList: Item[];
  fileType: Item[];
  fileTemplate: FileTemplate[];
  cognitoConfig: CognitoConfig;
  userAttribute?: any;
  OrgSubscription: { list: SubsList[]; size: 0 };
  globalMenuList: Item[];
  globalPermissionList: Item[];
  mccCategory: Item[];
  staticFileTemplate: StaticFileTemplate[];
  roleReferralProgram: Item[];
} = {
  switchOrgLoading: false,
  loading: false,
  changePasswordPermission: false,
  showSettingOrg: false,
  adminPortal: false,
  upgradePlanLoading: false,
  OrgSubscriptionLoading: false,
  upgradePlanFlag: false,
  userInfo: null,
  cognitoIdToken: null,
  cognitoAccessToken: null,
  cognitoRefreshToken: null,
  token: null,
  error: null,
  forgetPasswordLoading: false,
  resetValidationLoading: false,
  resetPasswordLoading: false,
  changePasswordLoading: false,
  resetForgetPasswordLoading: false,
  config: null,
  menuList: [],
  fileType: [],
  fileTemplate: [],
  cognitoConfig: {},
  OrgSubscription: {
    list: [],
    size: 0
  },
  globalMenuList: [],
  globalPermissionList: [],
  mccCategory: [],
  staticFileTemplate: [],
  roleReferralProgram: []
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    cognitoLoginStart: (state) => {
      state.loading = true;
    },
    cognitoLoginSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cognitoIdToken = action.payload.idToken;
      state.cognitoAccessToken = action.payload.accessToken;
      state.cognitoRefreshToken = action.payload.refreshToken;
      localStorage.setItem("new_hub", "1");
    },
    cognitoLoginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    cognitoSignUpStart: (state) => {
      state.loading = true;
    },
    cognitoSignUpSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    cognitoSignUpFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserRole: (state, action) => {
      if (state.userInfo) {
        state.userInfo.role = action.payload;
      }
    },
    logout: (state) => {
      state.userInfo = null;
      state.cognitoIdToken = null;
      state.cognitoAccessToken = null;
      state.cognitoRefreshToken = null;
      state.token = null;
      state.menuList = [];
      state.fileType = [];
      state.fileTemplate = [];
      state.config = null;
      state.adminPortal = false;
      state.upgradePlanFlag = false;

      const cognitoUser = cognitoPool.getCurrentUser();

      if (cognitoUser != null) {
        console.log("signing out");
        cognitoUser.signOut();
      }
      localStorage.clear();
    },
    forgetPasswordStart: (state) => {
      state.forgetPasswordLoading = true;
    },
    forgetPasswordSuccess: (state) => {
      state.forgetPasswordLoading = false;
    },
    forgetPasswordFail: (state, action) => {
      state.forgetPasswordLoading = false;
      state.error = action?.payload;
    },
    resetForgetPasswordStart: (state) => {
      state.resetForgetPasswordLoading = true;
    },
    resetForgetPasswordSuccess: (state) => {
      state.resetForgetPasswordLoading = false;
    },
    resetForgetPasswordFail: (state, action) => {
      state.resetForgetPasswordLoading = false;
      state.error = action?.payload;
    },
    resetValidationStart: (state) => {
      state.resetValidationLoading = true;
    },
    resetValidationSuccess: (state) => {
      state.resetValidationLoading = false;
    },
    resetValidationFail: (state, action) => {
      state.resetValidationLoading = false;
      state.error = action?.payload;
    },
    resetPasswordStart: (state) => {
      state.resetPasswordLoading = true;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordLoading = false;
    },
    resetPasswordFail: (state, action) => {
      state.resetPasswordLoading = false;
      state.error = action?.payload;
    },
    setChangePasswordLoading: (state, action) => {
      state.changePasswordLoading = action.payload;
    },
    setMenuItems: (state, action) => {
      state.menuList = action.payload;
    },
    setFileTypes: (state, action) => {
      state.fileType = action.payload;
    },
    setFileTemplate: (state, action) => {
      state.fileTemplate = action.payload;
    },
    setConfig: (state, action) => {
      state.config = {
        ...state.config,
        ...action.payload
      };
    },
    setCognitoConfig: (state, action) => {
      state.cognitoConfig = {
        ...state.cognitoConfig,
        ...action.payload
      };
    },
    setCongitoCurrentOrg: (state, action) => {
      state.cognitoConfig.currentOrganisation = action.payload;
    },
    setUserAttribute: (state, action) => {
      state.userAttribute = action.payload;
    },
    setAllowedPermissions: (state, action) => {
      state.cognitoConfig.allowedPermissions = action.payload;
    },
    setRoleAsllowedToAdd: (state, action) => {
      state.cognitoConfig.rolesAllowedToAdd = action.payload;
    },
    setSwitchOrgLoading: (state, action) => {
      state.switchOrgLoading = action.payload;
    },
    setParams: (state, action) => {
      state.cognitoConfig.params = action.payload;
    },
    setChangePasswordPermission: (state, action) => {
      state.changePasswordPermission = action.payload;
    },
    setShowSettingOrg: (state, action) => {
      state.showSettingOrg = action.payload;
    },
    setAdminPortal: (state, action) => {
      state.adminPortal = action.payload;
    },
    upgradePlanStart: (state) => {
      state.upgradePlanLoading = true;
    },
    upgradePlanSuccess: (state) => {
      state.upgradePlanLoading = false;
    },
    upgradePlanFail: (state, action) => {
      state.upgradePlanLoading = false;
      state.error = action.payload;
    },
    setOrgSubscriptionLoading: (state, action) => {
      state.OrgSubscriptionLoading = action.payload;
    },
    fetchOrgSubscription: (state, action) => {
      state.OrgSubscription = action.payload;
    },
    setUpgradePlanFlag: (state, action) => {
      state.upgradePlanFlag = action.payload;
    },
    setGlobalMenuList: (state, action) => {
      state.globalMenuList = action.payload;
    },
    setGlobalPermissionList: (state, action) => {
      state.globalPermissionList = action.payload;
    },
    setOrganisationSubscriptions: (state, action) => {
      state.cognitoConfig.organisationSubscriptions = action.payload;
    },
    setMccCategory: (state, action) => {
      state.mccCategory = action.payload;
    },
    setStaticFileTemplate: (state, action) => {
      state.staticFileTemplate = action.payload;
    },
    setRoleReferralProgram: (state, action) => {
      state.roleReferralProgram = action.payload;
    }
  }
});

const {
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFail,
  resetValidationStart,
  resetValidationSuccess,
  resetValidationFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  cognitoLoginStart,
  cognitoLoginFail,
  setUserAttribute,
  setUserInfo,
  setMenuItems,
  setFileTypes,
  setFileTemplate,
  setAllowedPermissions,
  setRoleAsllowedToAdd,
  setSwitchOrgLoading,
  setParams,
  resetForgetPasswordStart,
  resetForgetPasswordSuccess,
  resetForgetPasswordFail,
  cognitoSignUpStart,
  cognitoSignUpSuccess,
  cognitoSignUpFail,
  upgradePlanStart,
  upgradePlanSuccess,
  upgradePlanFail,
  setOrgSubscriptionLoading,
  fetchOrgSubscription,
  setGlobalMenuList,
  setGlobalPermissionList,
  setOrganisationSubscriptions,
  setMccCategory,
  setStaticFileTemplate,
  setRoleReferralProgram
} = loginSlice.actions;

export const {
  logout,
  setLoading,
  cognitoLoginSuccess,
  setUserRole,
  setCongitoCurrentOrg,
  setChangePasswordLoading,
  setCognitoConfig,
  setChangePasswordPermission,
  setShowSettingOrg,
  setAdminPortal,
  setUpgradePlanFlag
} = loginSlice.actions;

export const fetchConfigAction = () => {
  return async (dispatch: StoreDispatch) => {
    try {
      const {
        auth: { userInfo, cognitoAccessToken, adminPortal }
      } = store.getState();

      const userRole = userInfo?.role;
      const response = await getUiConfig();
      const structuredResponse = adminPortal
        ? response.data.get_ui_config.value.internal_web_config
        : response.data.get_ui_config.value.web_config;

      const { file_types: fileType, role_mapping: roleMapping, menu_list: internalMenuList } = structuredResponse;
      const {
        menu_list: menuList,
        permission_list: permissionList,
        mcc_category: mccCategory,
        file_templates: fileTemplate,
        static_file_templates: staticFileTemplate,
        role_referral_program_mapping: roleReferralProgram
      } = response.data.get_ui_config.value;
      const config = roleMapping.find((item: { role: string }) => item.role === userInfo?.role);

      dispatch(setGlobalMenuList(menuList));
      dispatch(setGlobalPermissionList(permissionList));
      dispatch(setFileTypes(fileType));
      dispatch(setFileTemplate(fileTemplate));
      dispatch(setStaticFileTemplate(staticFileTemplate));
      dispatch(setRoleReferralProgram(roleReferralProgram));
      dispatch(setMccCategory(mccCategory));
      dispatch(setRoleAsllowedToAdd(config?.roles_allowed_to_add ?? []));
      dispatch(setParams(config?.params ?? []));

      if (adminPortal) {
        const menuItems = internalMenuList.filter((menuItem: { id: string }) => config.menu_list.includes(menuItem.id));

        dispatch(setMenuItems(menuItems));
        dispatch(setAllowedPermissions(config?.permissions ?? []));
      }

      if (cognitoAccessToken) {
        const payload = new CognitoAccessToken({ AccessToken: cognitoAccessToken }).decodePayload();
        const organisations = JSON.parse(payload.available_relations);
        const currentOrganisation = organisations.find(
          (org: { organisation_id: string }) => org.organisation_id === payload.current_organisation
        );
        let subscriptionIds = payload.subscription_ids;

        if (!adminPortal) {
          if (subscriptionIds?.length > 0) {
            const subscriptionResponse = await listSubscription({
              limit: 100,
              page: 0,
              subscription_filter: {
                get_parent_subscription: true,
                is_active: true,
                subscription_ids: subscriptionIds,
                include_preferences: true
              }
            });
            const subscriptionDetails = subscriptionResponse.data.search_subscriptions.items;

            let aggregatedMenus: Item[] = [];
            let aggregatedPermissions: Item[] = [];

            subscriptionDetails.forEach((subscriptionData: any) => {
              const roleMenuMapping = subscriptionData.preferences?.menu?.role_menu_mapping;
              const rolePermissionMapping = subscriptionData.preferences?.permission?.role_permission_mapping;

              if (userRole) {
                const roleMenuIds = roleMenuMapping?.[userRole] || [];
                const allowedPermissions = rolePermissionMapping?.[userRole] || [];

                aggregatedMenus = [
                  ...aggregatedMenus,
                  ...menuList.filter((menu: { id: string }) => roleMenuIds.includes(menu.id))
                ];
                aggregatedPermissions = [...aggregatedPermissions, ...allowedPermissions];
              }
            });

            dispatch(setMenuItems(aggregatedMenus));
            dispatch(setAllowedPermissions(aggregatedPermissions));
          } else {
            const defaultSubscriptionResponse = await listSubscription({
              limit: 100,
              page: 0,
              subscription_filter: {
                get_parent_subscription: true,
                standard: "Y",
                is_active: true,
                include_preferences: true
              }
            });

            const defaultSubscription = defaultSubscriptionResponse.data?.search_subscriptions?.items[0];

            if (defaultSubscription) {
              subscriptionIds = [defaultSubscription.subscription_id];

              const roleMenuMapping = defaultSubscription.preferences?.menu?.role_menu_mapping;
              const rolePermissionMapping = defaultSubscription.preferences?.permission?.role_permission_mapping;

              let defaultMenus: Item[] = [];
              let defaultPermissions: Item[] = [];

              if (userRole) {
                const roleMenuIds = roleMenuMapping?.[userRole] || [];
                const allowedPermissions = rolePermissionMapping?.[userRole] || [];

                defaultMenus = [
                  ...defaultMenus,
                  ...menuList.filter((menu: { id: string }) => roleMenuIds.includes(menu.id))
                ];
                defaultPermissions = [...defaultPermissions, ...allowedPermissions];
              }

              dispatch(setMenuItems(defaultMenus));
              dispatch(setAllowedPermissions(defaultPermissions));
            }
          }
        }

        dispatch(setOrganisationSubscriptions(subscriptionIds));

        dispatch(
          setCognitoConfig({
            organisations,
            currentOrganisation,
            defaultOrgId: payload.default_organisation
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(logout());
    }
  };
};

let globalCognitoUser: CognitoUser | null = null;

export const loginAction = (data: LoginData, cb?: () => void, newUserLogin?: () => void) => {
  return async (dispatch: Dispatch) => {
    localStorage.clear();
    dispatch(cognitoLoginStart());
    const cognitoUser = new CognitoUser({
      Username: data.email,
      Pool: cognitoPool
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (response: CognitoUserSession) => {
        dispatch(
          cognitoLoginSuccess({
            idToken: response.getIdToken().getJwtToken(),
            accessToken: response.getAccessToken().getJwtToken(),
            refreshToken: response.getRefreshToken().getToken()
          })
        );

        dispatch(setLoading(true));
        const payload = response.getIdToken().payload;
        const customUserId = payload["custom:user_id"];

        if (payload.current_role) {
          const userInfoResponse = await getUserById({ user_id: customUserId });
          const userInfo = userInfoResponse.data.get_user;

          dispatch(
            setUserInfo({
              id: userInfo.user_id,
              displayId: userInfo?.display_id,
              cognitoUserName: userInfo.cognito_username,
              firstName: userInfo.attributes.first_name,
              lastName: userInfo.attributes.last_name,
              email: userInfo.email,
              fullName: userInfo.attributes.first_name + " " + userInfo.attributes.last_name,
              role: payload.current_role
            })
          );
          dispatch(setChangePasswordPermission(true));
          dispatch(setShowSettingOrg(true));
          dispatch(setLoading(false));

          if (cb) cb();
        } else {
          dispatch(
            setUserInfo({
              id: payload.user_id,
              cognitoUserName: payload["cognito:username"],
              firstName: payload.name,
              lastName: payload.family_name,
              email: payload.email,
              fullName: payload.name + " " + payload.family_name,
              contact: payload.phone_number
            })
          );
          dispatch(setLoading(false));
          if (newUserLogin) newUserLogin();
        }
      },
      onFailure: (error) => {
        immediateToast("error", {
          message: error.message,
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(cognitoLoginFail(error.message));
      },
      newPasswordRequired: (userAttributes) => {
        globalCognitoUser = cognitoUser;
        dispatch(setUserAttribute(userAttributes));
        dispatch(setShowSetPassword(true));
        dispatch(setLoading(false));
      }
    });
  };
};

export const signUpAction = (data: SignUpData, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    dispatch(cognitoSignUpStart());

    const { userName, email, password, firstName, lastName, contact } = data;

    const attributeList = [
      new CognitoUserAttribute({
        Name: "name",
        Value: firstName
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastName
      }),
      new CognitoUserAttribute({
        Name: "email",
        Value: email
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: contact
      })
    ];

    cognitoPool.signUp(userName, password, attributeList, [], (err, result) => {
      if (err) {
        dispatch(cognitoSignUpFail(err));
        immediateToast("error", {
          message: err.message,
          timeout: 3000,
          position: "topCenter"
        });
      } else {
        dispatch(cognitoSignUpSuccess());
        console.log("signUp result", result);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Registration successful. You can sign in now."
        });
        if (cb) cb();
      }
    });
  };
};

export const loginSSOAction = (code: string, cb?: () => void) => {
  const { cognitoConfig } = config;

  return async (dispatch: Dispatch) => {
    localStorage.clear();
    dispatch(cognitoLoginStart());
    try {
      const response = await axios.post(
        `${cognitoConfig.baseUrl}/oauth2/token`,
        new URLSearchParams({
          grant_type: "authorization_code",
          code,
          client_id: cognitoConfig.Auth.clientId,
          redirect_uri: cognitoConfig.redirectUrl
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      const verifier = CognitoJwtVerifier.create({
        userPoolId: config.COGNITO_USER_POOL_ID,
        tokenUse: "id",
        clientId: config?.COGNITO_CLIENT_ID
      });

      dispatch(
        cognitoLoginSuccess({
          idToken: response.data.id_token,
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token
        })
      );

      dispatch(setLoading(true));

      const payload = await verifier.verify(response.data.id_token);

      if (!payload["custom:user_id"]) {
        console.log("user iD not found"); //error hanling needed
      }
      const customUserId = payload["custom:user_id"] as string;

      const userInfoResponse = await getUserById({ user_id: customUserId });
      const userInfo = userInfoResponse.data.get_user;

      dispatch(
        setUserInfo({
          id: userInfo.user_id,
          cognitoUserName: userInfo.cognito_username,
          firstName: userInfo.attributes.first_name,
          lastName: userInfo.attributes.last_name,
          email: userInfo.email,
          fullName: userInfo.attributes.first_name + " " + userInfo.attributes.last_name,
          role: payload.current_role
        })
      );
      dispatch(setChangePasswordPermission(false));
      dispatch(setShowSettingOrg(false));
      dispatch(setLoading(false));

      if (cb) cb();
    } catch (error) {
      dispatch(cognitoLoginFail(error));
      console.log("SSO error", error);
    }

    if (cb) cb();
  };
};

export const setFirstTimePasswordAction = (password: string) => {
  return async (dispatch: Dispatch) => {
    const state = store.getState();

    if (globalCognitoUser && state.auth.userAttribute) {
      dispatch(setSetPasswordLoading(true));

      globalCognitoUser.completeNewPasswordChallenge(
        password,
        { user_id: state.auth.userAttribute.user_id },
        {
          onSuccess: async (session) => {
            dispatch(
              cognitoLoginSuccess({
                idToken: session.getIdToken().getJwtToken(),
                accessToken: session.getAccessToken().getJwtToken(),
                refreshToken: session.getRefreshToken().getToken()
              })
            );
            dispatch(setShowSetPassword(false));
          },
          onFailure: (error) => {
            dispatch(setSetPasswordLoading(false));
            dispatch(cognitoLoginFail(error));
          }
        }
      );
    } else {
      immediateToast("error", {
        message: "error while setting up password",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(setShowSetPassword(false));
    }
  };
};

export const forgetPasswordAction = (data: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(forgetPasswordStart());
    const cognitoUser = new CognitoUser({
      Username: data,
      Pool: cognitoPool
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        swal.fire({
          position: "center",
          icon: "success",
          title: "We have sent you the verification code to your registered email."
        });

        dispatch(forgetPasswordSuccess());
      },
      onFailure: (err: Error) => {
        dispatch(forgetPasswordFail(err));
      }
    });
  };
};

export const forgetResetPassword = (email: string, password: string, verificationCode: string, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    dispatch(resetForgetPasswordStart());
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool
    });

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => {
        swal.fire({
          position: "center",
          icon: "success",
          title: "Password reset Successfully"
        });

        dispatch(resetForgetPasswordSuccess());
        if (cb) cb();
      },
      onFailure: (err: Error) => {
        dispatch(resetForgetPasswordFail(err));
        swal.fire({
          position: "center",
          icon: "error",
          title: `Failed: ${err}`
        });
      }
    });
  };
};

export const resetValidationAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch(resetValidationStart());
    try {
      dispatch(resetValidationSuccess());
    } catch (error) {
      dispatch(resetValidationFail(error));
      swal.fire("Opps!", "The Link is expired", "warning");
    }
  };
};

export const resetPasswordAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    try {
      dispatch(resetPasswordSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Password Reset Successfully"
      });
    } catch (error) {
      dispatch(resetPasswordFail(error));
      swal.fire("Opps!", "Reset Password is Falied", "warning");
    }
  };
};

export const switchOrganisationAction = (data: SwitchOrganisationRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(setSwitchOrgLoading(true));
    try {
      await switchOrganisation(data);
      if (cb) cb();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setSwitchOrgLoading(false));
    }
  };
};

export const upgradePlanAction = (data: upgradePlanRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(upgradePlanStart());
    try {
      await upgradePlan(data);
      dispatch(upgradePlanSuccess());
      dispatch(setUpgradePlanFlag(true));
      if (cb) cb();
    } catch (error) {
      dispatch(upgradePlanFail(error));
    }
  };
};

export const fetchOrganisationSubscriptionAction = (subscriptionIds: string[], cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(setOrgSubscriptionLoading(true));
    try {
      const data = {
        limit: 100,
        page: 0,
        subscription_filter: {
          subscription_ids: subscriptionIds
        }
      };
      const response = await listSubscription(data);
      const structuredResponse = response.data.search_subscriptions.items?.map((data: any) => ({
        subsId: data.subscription_id,
        title: data.title,
        subTitle: data.subtitle,
        description: data.description,
        date: data.created_at,
        orgType: data.organisation_type,
        status: data.is_active,
        standard: data.standard,
        subscription_category: data.subscription_category
      }));

      dispatch(
        fetchOrgSubscription({
          list: structuredResponse,
          size: response.data.search_subscriptions.total_size
        })
      );

      dispatch(setOrgSubscriptionLoading(false));
      if (cb) cb();
    } catch (error) {
      dispatch(setOrgSubscriptionLoading(false));
      console.error(error);
    }
  };
};

export default loginSlice.reducer;
