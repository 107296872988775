import { createSlice } from "@reduxjs/toolkit";
import store, { StoreDispatch } from "../store";
import { SearchUserRequest, getCardSetting, searchUser } from "src/network/graphql/userService";
import { walletDetail } from "../walletDetail/walletDetailSlice";
import { getWalletDetail, searchWallet } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
import { SinglePayRequest, singlePay } from "src/network/graphql/paymentService";
import swal from "sweetalert2";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { Transaction } from "../transactions/transactionSlice";
import { Users } from "../users/usersSlice";
import { PARTICIPANT } from "src/components/constant/constant";
import moment from "moment";

export type CardSetting = {
  card_setting_id: string;
  card_setting_status: string;
  status: string;
  ppan: string;
  card_id: string;
  wallet_id: string;
  expires_at: string;
  sub_type: string;
  user_id: string;
};

const initialUserCard: CardSetting[] = [];

const initialTransaction: Transaction[] = [];
const initialUsers: Users[] = [];

interface WalletDetails {
  [walletId: string]: walletDetail;
}

interface WalletList {
  [walletId: string]: {
    list: Wallet[];
    total: number;
  };
}

const homeSlice = createSlice({
  name: "home",
  initialState: {
    userCardLoading: false,
    activityLoading: false,
    userLoading: false,
    error: null,
    userCards: initialUserCard,
    walletDetails: {} as WalletDetails,
    wallets: {} as WalletList,
    transactions: {
      list: initialTransaction,
      total: 0
    },
    users: {
      list: initialUsers,
      total: 0
    }
  },
  reducers: {
    getUserCardStart: (state) => {
      state.userCardLoading = true;
    },
    getUserCardSuccess: (state, action) => {
      state.userCardLoading = false;
      state.userCards = action.payload ?? [];
      state.error = null;
    },
    getUserCardFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    fetchWalletDetailsStart: (state) => {
      state.userCardLoading = true;
    },
    fetchWalletDetailsSuccess: (state, action) => {
      state.userCardLoading = false;
      state.walletDetails = action.payload.walletDetails;
      state.wallets = action.payload.wallets;
    },
    fetchWalletDetailsFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    // fetchWalletsStart: (state) => {
    //   state.userCardLoading = true;
    // },
    // fetchWalletsSuccess: (state, action) => {
    //   state.userCardLoading = false;
    //   state.wallets = action.payload;
    // },
    // fetchWalletsFail: (state, action) => {
    //   state.userCardLoading = false;
    //   state.error = action.payload;
    // },
    singlePayStart: (state) => {
      state.userCardLoading = true;
    },
    singlePaySuccess: (state) => {
      state.userCardLoading = false;
      state.error = null;
    },
    singlePayFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    fetchActivityStart: (state) => {
      state.activityLoading = true;
    },
    fetchActivitySuccess: (state, action) => {
      state.activityLoading = false;
      state.transactions = action.payload;
    },
    fetchActivityFail: (state, action) => {
      state.activityLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    }
  }
});

const {
  getUserCardStart,
  getUserCardSuccess,
  getUserCardFail,
  fetchWalletDetailsStart,
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFail,
  // fetchWalletsStart,
  // fetchWalletsSuccess,
  // fetchWalletsFail,
  singlePayStart,
  singlePaySuccess,
  singlePayFail,
  fetchActivityStart,
  fetchActivitySuccess,
  fetchActivityFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail
} = homeSlice.actions;

export const getUserCardAction = (cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserCardStart());
    try {
      const { auth } = store.getState();
      const cardSetting = await getCardSetting(String(auth?.userInfo?.id));

      const sortedCards = cardSetting.data.get_card_settings
        .filter((card: any) => card.status === "ACTIVE")
        .sort((a: any, b: any) => moment(b.updated_at).diff(moment(a.updated_at)));

      const cards = sortedCards.map((card: any) => ({
        card_setting_id: card?.card_setting_id,
        card_setting_status: card?.card_setting_status,
        status: card?.status,
        ppan: card?.ppan,
        card_id: card?.card_id,
        wallet_id: card?.wallet_id,
        expires_at: card?.expires_at,
        sub_type: card?.sub_type,
        user_id: card?.user_id
      }));

      dispatch(getUserCardSuccess(cards));
      if (cb) cb();
    } catch (err) {
      dispatch(getUserCardFail(err));
    }
  };
};

export const fetchWalletDetailsAction = (userCards: CardSetting[]) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailsStart());
    try {
      const walletDetails: any = {};
      const wallets: WalletList = {};

      for (const card of userCards) {
        if (card.wallet_id) {
          const walletResponse = await getWalletDetail(card.wallet_id);
          const wallet = walletResponse.data.get_wallet;

          walletDetails[card.wallet_id] = {
            wallet: {
              name: wallet.name,
              description: wallet.description,
              id: wallet.wallet_id,
              balance: wallet.balance,
              participant: {
                id: wallet.user.user_id,
                firstName: wallet.user.attributes.first_name,
                lastname: wallet.user.attributes.last_name
              }
            }
          };

          const response = await searchWallet({ walletFilter: { user_ids: [wallet.user.user_id] } });
          const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
            id: wallet.wallet_id,
            displayId: wallet.display_id,
            description: wallet.description,
            balance: wallet.balance,
            name: wallet.name
          }));

          wallets[card.wallet_id] = {
            list: structuredResponse,
            total: structuredResponse.length
          };
        }
      }

      dispatch(fetchWalletDetailsSuccess({ walletDetails, wallets }));
    } catch (error) {
      dispatch(fetchWalletDetailsFail(error));
    }
  };
};

// export const fetchWalletListAction = (userId: string) => {
//   return async (dispatch: StoreDispatch) => {
//     dispatch(fetchWalletsStart());
//     try {
//       const response = await getUserWallets(userId);

//       const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
//         id: wallet.wallet_id,
//         displayId: wallet.display_id,
//         description: wallet.description,
//         balance: wallet.balance,
//         // status: wallet.wallet_status,
//         name: wallet.name
//         // participant: { role: "PARTICIPANT", fullName: wallet.description }
//       }));

//       dispatch(
//         fetchWalletsSuccess({
//           list: structuredResponse,
//           total: structuredResponse.length
//         })
//       );
//     } catch (error) {
//       dispatch(fetchWalletsFail(error));
//     }
//   };
// };

export const singlePayAction = (data: SinglePayRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(singlePayStart());
    try {
      const response = await singlePay(data);

      dispatch(singlePaySuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Success! You're ready to pay",
        showConfirmButton: false,
        timer: 2500
      });
      return response;
    } catch (err) {
      dispatch(singlePayFail(err));
    }
  };
};

export const fetchActivityAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchActivityStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data.data)
      }));

      dispatch(
        fetchActivitySuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchActivityFail(err));
    }
  };
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        // user_filter: {
        //   is_active: data.isActive,
        //   email: data.email,
        //   first_name: data.firstName,
        //   last_name: data.lastName,
        //   organisation_id: data.organisationId
        // },
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing
        .filter((user: any) => user.current_organisation_role === PARTICIPANT)
        .map((user: any) => ({
          id: user.display_id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.email,
          role: "not available",
          status: user.is_active,
          userId: user.user_id
        }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export default homeSlice.reducer;
