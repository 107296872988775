/* eslint-disable no-nested-ternary */
import { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addUserModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { roleMapping, reverseRoleMapping } from "src/components/constant/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppSelector, useAppDispatch } from "src/page/store";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Autocomplete,
  TextField,
  CircularProgress
} from "@mui/material";
import { CreateUserRequest, checkEmailAvailability, checkUserNameAvailability } from "src/network/graphql/userService";
import { countryCodeData } from "src/types/countryCode";
import UserNameInput from "src/components/userNameInput/UserNameInput";
import { emailValidation, userNameValidation, generateUsername } from "src/utils/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { fetchUserDetailAction } from "src/page/userDetail/userDetailSlice";

interface AddUserModalProps {
  handleClose: () => void;
  onAddUser: (_addUser: CreateUserRequest, _close: boolean, _cb?: () => void, _fcb?: () => void) => void;
  rootContainer?: string;
  closeButton?: boolean;
  secondaryButton?: boolean;
  heading?: string;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
  role?: string;
  userloading?: boolean;
}
export default function AddUserModal({
  handleClose,
  onAddUser,
  rootContainer,
  closeButton = true,
  secondaryButton = true,
  heading = "Add User",
  buttonText,
  role,
  userloading = false
}: AddUserModalProps) {
  const { cognitoConfig, userInfo } = useAppSelector((state) => state.auth);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { userDetail } = useAppSelector((state) => state.userDetail);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(fetchUserDetailAction(userInfo.id));
    }
  }, [userInfo?.id, dispatch]);

  useEffect(() => {
    if (userDetail?.user) {
      setInput((prevInput) => ({
        ...prevInput,
        line1: userDetail.user.line1 || "",
        line2: userDetail.user.line2 || "",
        city: userDetail.user.city || "",
        state: userDetail.user.state || "",
        postcode: userDetail.user.zipcode || "",
        country: userDetail.user.country || "Australia"
      }));
    }
  }, [userDetail]);

  const [input, setInput] = useState({
    initial: "Mr.",
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    gender: "male",
    phoneCountryCode: "+61",
    phone: "",
    role: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postcode: "",
    country: "Australia",
    userName: ""
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    role: "",
    line1: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    userName: ""
  });

  const handleCancelClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (role) {
      setInput((prevInput) => ({
        ...prevInput,
        role
      }));
    }
  }, [role]);

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value
    });
  };

  const handleCancel = async () => {
    if (
      !input.email?.trim() ||
      !input.firstName?.trim() ||
      !input.lastName?.trim() ||
      !input.role?.trim() ||
      !input.dob?.trim() ||
      !input.city?.trim() ||
      !input.state?.trim() ||
      !input.postcode?.trim() ||
      !input.country?.trim() ||
      !input.phone?.trim() ||
      !input.line1?.trim()
    ) {
      setError({
        email: input.email ? (emailValidation(input.email.trim()) ? "" : "Invalid email") : "Email is required",
        firstName: input.firstName ? "" : "First name is required",
        lastName: input.lastName ? "" : "Last name is required",
        role: input.role ? "" : "Role is required",
        dob: input.dob ? "" : "DOB is required",

        phone: input.phone
          ? parsePhoneNumberFromString(input.phoneCountryCode + input.phone)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Phone is required",
        line1: input.line1 ? "" : "Line1 is required",
        city: input.city ? "" : "City is required",
        state: input.state ? "" : "State is required",
        postcode: input.postcode ? "" : "Postcode is required",
        country: input.country ? "" : "Country is required",
        userName: input.userName ? (userNameValidation(input.userName) ? error.userName : "") : "Username is required"
      });
      return;
    }

    const trimmedEmail = input.email.trim().toLowerCase();
    const trimmedUserName = input.userName.trim().toLowerCase();

    const usernameError = userNameValidation(trimmedUserName);

    if (usernameError) {
      setError((prevError) => ({
        ...prevError,
        userName: usernameError
      }));
      return;
    }

    setSubmitLoading(true);

    const isUserNameAvailable = await checkUserNameAvailability(trimmedUserName);

    if (!isUserNameAvailable.data.is_username_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        userName: "username already exists"
      });
      return;
    }
    const isEmailAvailable = await checkEmailAvailability(trimmedEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        email: "email already exists"
      });
      return;
    }

    onAddUser(
      {
        ...input,
        firstName: input.firstName.trim(),
        lastName: input.lastName.trim(),
        email: trimmedEmail,
        userName: trimmedUserName,
        organisationId: cognitoConfig.currentOrganisation?.organisation_id,
        phone: input.phoneCountryCode + input.phone
      } as CreateUserRequest,
      true,
      () => {
        setSubmitLoading(false);
      },
      () => {
        setSubmitLoading(false);
        setError({
          email: "",
          firstName: "",
          lastName: "",
          role: "",
          dob: "",
          phone: "",
          line1: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          userName: ""
        });
      }
    );
  };

  const handleContactBlur = () => {
    const phoneNumber = parsePhoneNumberFromString(input.phoneCountryCode + input.phone);

    if (!phoneNumber || !phoneNumber.isValid()) {
      setError({
        ...error,
        phone: "Invalid contact number"
      });
    } else {
      setError({
        ...error,
        phone: ""
      });
    }
  };

  const handleEmailValidation = () => {
    const trimmedEmail = input.email.trim().toLowerCase();

    if (!emailValidation(trimmedEmail)) {
      setError({
        ...error,
        email: "Invalid email"
      });
    } else {
      setError({
        ...error,
        email: ""
      });
    }
  };

  const handleAddUser = async () => {
    if (
      !input.email.trim() ||
      !input.firstName.trim() ||
      !input.lastName.trim() ||
      !input.role.trim() ||
      !input.dob.trim() ||
      !input.city.trim() ||
      !input.state.trim() ||
      !input.postcode.trim() ||
      !input.country.trim() ||
      !input.phone.trim() ||
      !input.line1.trim()
    ) {
      setError({
        email: input.email ? (emailValidation(input.email.trim()) ? "" : "Invalid email") : "Email is required",
        firstName: input.firstName ? "" : "First name is required",
        lastName: input.lastName ? "" : "Last name is required",
        role: input.role ? "" : "Role is required",
        dob: input.dob ? "" : "DOB is required",

        phone: input.phone
          ? parsePhoneNumberFromString(input.phoneCountryCode + input.phone)?.isValid()
            ? ""
            : "Invalid contact number"
          : "Phone is required",
        line1: input.line1 ? "" : "Line1 is required",
        city: input.city ? "" : "City is required",
        state: input.state ? "" : "State is required",
        postcode: input.postcode ? "" : "Postcode is required",
        country: input.country ? "" : "Country is required",
        userName: input.userName ? (userNameValidation(input.userName) ? error.userName : "") : "Username is required"
      });
      return;
    }

    const trimmedEmail = input.email.trim().toLowerCase();
    const trimmedUserName = input.userName.trim().toLowerCase();

    const usernameError = userNameValidation(trimmedUserName);

    if (usernameError) {
      setError((prevError) => ({
        ...prevError,
        userName: usernameError
      }));
      return;
    }

    setSubmitLoading(true);

    const isUserNameAvailable = await checkUserNameAvailability(trimmedUserName);

    if (!isUserNameAvailable.data.is_username_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        userName: "username already exists"
      });
      return;
    }
    const isEmailAvailable = await checkEmailAvailability(trimmedEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setSubmitLoading(false);
      setError({
        ...error,
        email: "email already exists"
      });
      return;
    }

    onAddUser(
      {
        ...input,
        firstName: input.firstName.trim(),
        lastName: input.lastName.trim(),
        email: trimmedEmail,
        userName: trimmedUserName,
        organisationId: cognitoConfig.currentOrganisation?.organisation_id,
        phone: input.phoneCountryCode + input.phone
      } as CreateUserRequest,
      false,
      () => {
        setInput({
          initial: "Mr.",
          firstName: "",
          lastName: "",
          dob: "",
          email: "",
          gender: "Male",
          phoneCountryCode: "+61",
          phone: "",
          role: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postcode: "",
          country: "Australia",
          userName: ""
        });
        setError({
          email: "",
          firstName: "",
          lastName: "",
          role: "",
          dob: "",
          phone: "",
          line1: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          userName: ""
        });
        setSubmitLoading(false);
      },
      () => {
        setSubmitLoading(false);
        setError({
          email: "",
          firstName: "",
          lastName: "",
          role: "",
          dob: "",
          phone: "",
          line1: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          userName: ""
        });
      }
    );
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setInput({
      ...input,
      role: value ? reverseRoleMapping[value] : "Participant"
    });
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    setInput({ ...input, [event.target.value]: event.target.value });
  };

  const [noEmailCheckbox, setNoEmailCheckbox] = useState(false);
  const [noPhoneCheckbox, setNoPhoneCheckbox] = useState(false);
  const [addressCheckbox, setAddressCheckbox] = useState(false);

  const generateUniqueEmail = async () => {
    const baseEmail = `${input.userName}@spendable.com.au`;
    const generatedEmail = baseEmail;
    let attempts = 0;

    do {
      const isEmailAvailable = await checkEmailAvailability(generatedEmail);

      if (isEmailAvailable.data.is_email_available) {
        setInput((prevInput) => ({
          ...prevInput,
          email: generatedEmail
        }));
        setError((prevError) => ({
          ...prevError,
          email: ""
        }));
        return;
      }
      attempts++;
    } while (attempts < 3);

    setError((prevError) => ({
      ...prevError,
      email: "Unable to generate a unique email"
    }));
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNoEmailCheckbox(e.target.checked);
    if (e.target.checked) {
      if (input.firstName.trim() && input.lastName.trim()) {
        generateUniqueEmail();
      }
    } else {
      setInput((prevInput) => ({
        ...prevInput,
        email: ""
      }));
    }
  };

  const handleNameChange = async () => {
    if (input.firstName.trim() && input.lastName.trim()) {
      const formattedFirstName = input.firstName.trim().replace(/\s+/g, "_");
      const formattedLastName = input.lastName.trim().replace(/\s+/g, "_");

      const newUsername = await generateUsername(formattedFirstName, formattedLastName);

      if (newUsername) {
        setInput((prevInput) => ({
          ...prevInput,
          userName: newUsername
        }));
        setError((prevError) => ({
          ...prevError,
          userName: ""
        }));

        if (noEmailCheckbox) {
          generateUniqueEmail();
        }
      } else {
        setError((prevError) => ({
          ...prevError,
          userName: "Could not generate a unique username"
        }));
      }
    }
  };

  useEffect(() => {
    if (noEmailCheckbox && input.userName) {
      generateUniqueEmail();
    }
  }, [noEmailCheckbox, input.userName]);

  const handleAddressCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setAddressCheckbox(isChecked);

    if (isChecked) {
      // Clear the address fields
      setInput((prevInput) => ({
        ...prevInput,
        line1: "",
        line2: "",
        city: "",
        state: "",
        postcode: "",
        country: "Australia"
      }));
    } else if (userDetail?.user) {
      // Use values from userDetail.user
      setInput((prevInput) => ({
        ...prevInput,
        line1: userDetail.user.line1 || "",
        line2: userDetail.user.line2 || "",
        city: userDetail.user.city || "",
        state: userDetail.user.state || "",
        postcode: userDetail.user.zipcode || "",
        country: userDetail.user.country || "Australia"
      }));
    }
  };

  const handlePhoneCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setNoPhoneCheckbox(isChecked);

    if (isChecked) {
      if (userDetail?.user) {
        const parsedPhone = parsePhoneNumberFromString(userDetail.user.phoneNumber || "");
        const localPhone = parsedPhone ? parsedPhone.nationalNumber : "";

        setInput((prevInput) => ({
          ...prevInput,
          phone: localPhone
        }));

        setError((prevError) => ({
          ...prevError,
          phone: ""
        }));
      }
    } else {
      // Clear the phone field
      setInput((prevInput) => ({
        ...prevInput,
        phone: ""
      }));
    }
  };

  return (
    <div className={`${styles.referModalBox} ${rootContainer}`}>
      <main>
        <header className={styles.referModalTop}>
          <form>
            <div className={styles.close}>
              <h1>{heading}</h1>
              {closeButton && (
                <button onClick={handleCancelClose} aria-label="Close modal">
                  <CloseIcon onClick={handleCancelClose} />
                </button>
              )}
            </div>
            {userloading ? (
              <div className={styles.progress}>
                <CircularProgress sx={{ color: "#0f172a" }} />
              </div>
            ) : (
              <>
                <section className={styles.newUser}>
                  <h3>Personal Information</h3>
                  <div className={styles.name}>
                    <div className={styles.initial}>
                      <FormControl sx={{ m: 1, margin: 0 }}>
                        <Select
                          value={input.initial}
                          onChange={handleSelectChange}
                          displayEmpty
                          aria-label="Select initial"
                          inputProps={{ "aria-label": "Without label", name: "initial" }}
                          sx={{
                            height: 49
                          }}
                        >
                          <MenuItem value="Mr.">Mr.</MenuItem>
                          <MenuItem value="Ms.">Ms.</MenuItem>
                          <MenuItem value="Mx.">Mx.</MenuItem>
                          <MenuItem value="Mrs.">Mrs.</MenuItem>
                          <MenuItem value="Mast.">Mast.</MenuItem>
                          <MenuItem value="Miss.">Miss.</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={styles.Fname}>
                      <label htmlFor="firstname">First Name</label>
                      <input
                        id="firstname"
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.firstName}
                        name="firstName"
                        onChange={handleInput}
                        onBlur={() => handleNameChange()}
                      />
                      {error.firstName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.firstName}
                        </div>
                      )}
                    </div>
                    <div className={styles.Lname}>
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        id="lastname"
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.lastName}
                        name="lastName"
                        onChange={handleInput}
                        onBlur={() => handleNameChange()}
                      />
                      {error.lastName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.dob}>
                      <label htmlFor="dob">DOB:</label>
                      <input id="dob" type="date" value={input.dob} name="dob" onChange={handleInput} />
                      {error.dob && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.dob}
                        </div>
                      )}
                    </div>
                    <div className={styles.email}>
                      <div className={styles.labelWithCheckbox}>
                        <label htmlFor="email">User Email:</label>
                        <div className={styles.checkboxContainer}>
                          <input
                            type="checkbox"
                            id="noEmailCheckbox"
                            checked={noEmailCheckbox}
                            onChange={handleCheckboxChange}
                            className={styles.checkbox}
                          />
                          <label htmlFor="noEmailCheckbox" className={styles.checkboxLabel}>
                            User does not have email
                          </label>
                        </div>
                      </div>
                      <input
                        id="email"
                        type="email"
                        placeholder="example@gmail.com"
                        value={input.email}
                        name="email"
                        onChange={handleInput}
                        onBlur={handleEmailValidation}
                      />
                      {error.email && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.gender}>
                      <label>Gender:</label>
                      <FormControl sx={{ m: 1, margin: 0 }}>
                        <Select
                          value={input.gender}
                          onChange={handleSelectChange}
                          displayEmpty
                          aria-label="Select gender"
                          inputProps={{ "aria-label": "Without label", name: "gender" }}
                          sx={{
                            height: 49
                          }}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="unknown">Non Binary</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={styles.phone}>
                      <div className={styles.labelWithCheckbox}>
                        <label htmlFor="phone-input">Contact:</label>
                        <div className={styles.checkboxContainer}>
                          <input
                            type="checkbox"
                            id="noPhoneCheckbox"
                            checked={noPhoneCheckbox}
                            onChange={handlePhoneCheckboxChange}
                            className={styles.checkbox}
                          />
                          <label htmlFor="noPhoneCheckbox" className={styles.checkboxLabel}>
                            User does not have a phone
                          </label>
                        </div>
                      </div>
                      <div className={styles.mobileSelect}>
                        <Select
                          value={input.phoneCountryCode || "+61"}
                          onChange={handleCountryCodeChange}
                          id="country-code-select"
                          displayEmpty
                          name="phoneCountryCode"
                          className={styles.countryCodeSelect}
                          sx={{ height: "50px" }}
                          disabled={noPhoneCheckbox}
                          inputProps={{
                            "aria-label": "Select country code"
                          }}
                        >
                          {countryCodeData.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.code}
                            </MenuItem>
                          ))}
                        </Select>
                        <input
                          id="phone-input"
                          type="text"
                          placeholder="4745625522"
                          value={input.phone}
                          name="phone"
                          onChange={handleInput}
                          onBlur={handleContactBlur}
                          disabled={noPhoneCheckbox}
                        />
                      </div>

                      {error.phone && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <br></br>
                  <hr></hr>
                  <h3 className={styles.labelWithCheckbox2}>
                    Address
                    <div className={styles.checkboxContainer2}>
                      <input
                        type="checkbox"
                        id="addressCheckbox"
                        checked={addressCheckbox}
                        onChange={handleAddressCheckboxChange}
                        className={styles.checkbox}
                      />
                      <label htmlFor="addressCheckbox" className={styles.checkboxLabel2}>
                        User doesn’t live at this address
                      </label>
                    </div>
                  </h3>{" "}
                  <div className={styles.userrow}>
                    <div className={styles.addressLine1}>
                      <label htmlFor="address-line1">Line 1 :</label>
                      <div className={styles.inputWerror}>
                        <input
                          id="address-line1"
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.line1}
                          name="line1"
                          onChange={handleInput}
                        />
                        {error.line1 && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.line1}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.addressLine2}>
                      <label htmlFor="address-line2">Line 2 :</label>
                      <input
                        id="address-line2"
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.line2}
                        name="line2"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.city}>
                      <label htmlFor="city">City :</label>
                      <div className={styles.inputWerror}>
                        <input
                          id="city"
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.city}
                          name="city"
                          onChange={handleInput}
                        />
                        {error.city && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.city}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.state}>
                      <label htmlFor="state">State :</label>
                      <div className={styles.inputWerror}>
                        <input
                          id="state"
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.state}
                          name="state"
                          onChange={handleInput}
                        />
                        {error.state && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.state}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.postcode}>
                      <label htmlFor="postcode">Postcode :</label>
                      <div className={styles.inputWerror}>
                        <input
                          id="postcode"
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.postcode}
                          name="postcode"
                          onChange={handleInput}
                        />
                        {error.postcode && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.postcode}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.country}>
                      <label htmlFor="country">Country :</label>
                      <div className={styles.inputWerror}>
                        <input
                          id="country"
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.country}
                          name="country"
                          disabled
                        />
                        {error.country && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.country}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                {!role && (
                  <section className={styles.Role}>
                    <label htmlFor="role">Role</label>
                    <Autocomplete
                      id="role"
                      aria-label="Select role"
                      className={styles.autoComplete}
                      filterOptions={(x) => x}
                      options={cognitoConfig?.rolesAllowedToAdd?.map((roles: any) => roleMapping[roles]) || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
                      value={roleMapping[input.role]}
                      onChange={handleParticipantChange}
                    />
                    {error.role && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.role}
                      </div>
                    )}
                  </section>
                )}
                <UserNameInput
                  error={error.userName}
                  setError={(value: string) => {
                    setError({
                      ...error,
                      userName: value
                    });
                  }}
                  value={input.userName}
                  setValue={(value) => {
                    setInput((prevInput) => ({
                      ...prevInput,
                      userName: value
                    }));
                  }}
                />
              </>
            )}
          </form>
        </header>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {secondaryButton && (
              <Button
                aria-label="secondary button"
                className={styles.cancelBtn}
                variant="text"
                onClick={handleCancel}
                disabled={submitLoading || userloading}
              >
                {submitLoading || userloading ? (
                  <CircularProgress sx={{ color: "#1e293b" }} />
                ) : (
                  buttonText?.secondaryButton || "Add & Back To List"
                )}
              </Button>
            )}
            <Button
              aria-label="primary button"
              className={styles.sendBtn}
              variant="contained"
              onClick={handleAddUser}
              disabled={submitLoading || userloading}
            >
              {submitLoading || userloading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                buttonText?.primaryButton || "Add & another User"
              )}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
