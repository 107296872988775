import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./subscriptionModal.module.scss";
import PricingPlanCard from "src/components/subscriptionPlan/SubscriptionCard/PricingPlanCard";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { useState, useEffect } from "react";
import { fetchListSubscription, SubscriptionList } from "src/page/onBoarding/onBoardingSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { connectOrganisationToSubscriptionAction } from "src/page/childrenPlans/childrenPlansSlice";

interface SubscriptionModalProps {
  onSuccess: () => void;
}

export default function SubscriptionModal({ onSuccess }: SubscriptionModalProps) {
  const dispatch = useAppDispatch();
  const { subscriptionList } = useAppSelector((state) => state.onBoarding);
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (cognitoConfig?.currentOrganisation?.organisation_id) {
      dispatch(fetchListSubscription(cognitoConfig?.currentOrganisation?.organisation_id));
    }
  }, [dispatch, cognitoConfig?.currentOrganisation?.organisation_id]);

  const handlePlanClick = (subscription: SubscriptionList) => {
    if (isConnecting) return;

    setIsConnecting(true);

    const organisationId = cognitoConfig.currentOrganisation?.organisation_id;
    if (!organisationId) {
      console.error("Organisation ID is missing.");
      setIsConnecting(false);
      return;
    }

    dispatch(
      connectOrganisationToSubscriptionAction(organisationId, subscription.subsId, () => {
        onSuccess();
      })
    );

    setIsConnecting(false);
  };

  const visibleCards = Math.min(subscriptionList.list.length, 3);
  const centerSlidePercentage = visibleCards === 1 ? 100 : visibleCards === 2 ? 50 : 33;
  const centerMode = visibleCards > 1;

  return (
    <div className={styles.rootContainer}>
      <main className={styles.modalTop}>
        <h5 style={{ fontSize: "25px" }}>Select a Subscription Plan</h5>

        {subscriptionList.list.length === 0 ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress size={40} />
          </div>
        ) : (
          <div className={styles.carouselWrapper}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={subscriptionList.list.length > 1}
              showArrows={subscriptionList.list.length > 1}
              centerMode={centerMode}
              centerSlidePercentage={centerSlidePercentage}
              selectedItem={currentIndex}
              onChange={setCurrentIndex}
              emulateTouch={true}
            >
              {subscriptionList.list.map((subscription: SubscriptionList, index) => (
                <div
                  key={subscription.subsId}
                  className={styles.cardWrapper}
                  style={{
                    transform: index === currentIndex ? "scale(1.1)" : "scale(0.9)",
                    transition: "transform 0.3s ease",
                    opacity: index === currentIndex ? 1 : 0.7,
                    cursor: "pointer",
                    padding: "0 10px"
                  }}
                >
                  <PricingPlanCard
                    title={subscription.title}
                    subTitle={subscription.subTitle}
                    description={subscription.description}
                    category={subscription.subscription_category}
                    onButtonClick={() => handlePlanClick(subscription)}
                    containerStyles={styles.pricingCard}
                    buttonLabel={isConnecting ? <CircularProgress size={24} color="inherit" /> : "Select Plan"}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </main>
    </div>
  );
}
