import { dateTimeFormat } from "src/utils/utils";
import styles from "./plans.module.scss";
import { SubsList } from "./plansSlice";
import PlanPreviewModal from "src/components/subscriptionPlan/planPreviewModal/PlanPreviewModal";
import { planCategoryMapping } from "src/components/constant/constant";
// import { Link } from "react-router-dom";

const columns = [
  {
    title: "Id",
    dataIndex: "subsId",
    key: "subsId"
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date: string) => <div>{dateTimeFormat(date).date}</div>
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title"
    // render: (title: string, data: SubsList) => <Link to={`/subscription/${data.subsId}`}>{title}</Link>
  },
  // {
  //   title: "SubTitle",
  //   dataIndex: "subTitle",
  //   key: "subTitle"
  // },
  {
    title: "Organisation Type",
    dataIndex: "orgType",
    key: "orgType"
  },
  {
    title: "Subscription Category",
    dataIndex: "subscription_category",
    key: "subscription_category",
    render: (subscription_category: string) => (
      <div className={styles.tableCategory}>{planCategoryMapping[subscription_category]}</div>
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // width: 150,
    render: (status: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${status ? styles.green : styles.grey}`}>{status ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "Preview",
    dataIndex: "preview",
    key: "preview",
    render: (preview: string, data: SubsList) => (
      <PlanPreviewModal
        title={data.title}
        subTitle={data.subTitle}
        description={data.description}
        category={data.subscription_category}
      />
    )
  }
];

export default columns;
