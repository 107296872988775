import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import styles from "./blockmerchant.module.scss";
import { Autocomplete, Avatar, CircularProgress, Skeleton, Stack, TextField, Checkbox } from "@mui/material";
import {
  blockMerchantAction,
  changePreferenceAction,
  fetchWalletListAction,
  getParticipantWallet,
  setUsers,
  setWallets,
  Transaction
} from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import CloseIcon from "@mui/icons-material/Close";
import { Wallet } from "src/page/wallets/walletSlice";
import { SearchWalletRequest } from "src/network/graphql/walletService";
import { bgColorbyName } from "src/variable/randomColor";
import { getTransactionValue } from "src/utils/utils";

interface Props {
  data: Transaction;
  handleClose: () => void;
}

export default function BlockMerchant({ data, handleClose }: Props) {
  const dispatch = useAppDispatch();
  const { walletList, participantWallet, participantWalletLoading, blockMerchantLoading } = useAppSelector(
    (state) => state.transaction
  );
  const [selectedWallets, setSelectedWallets] = useState<Wallet[]>([]);

  const handleCancel = () => {
    dispatch(setUsers([]));
    dispatch(setWallets([]));
    handleClose();
  };

  useEffect(() => {
    if (data?.data?.wallet_id) {
      dispatch(getParticipantWallet(data.data.wallet_id));
    }
  }, [data]);

  useEffect(() => {
    if (walletList?.list && participantWallet?.wallet?.id) {
      const defaultWallet = walletList.list.find((wallet) => wallet.id === participantWallet.wallet.id);

      if (defaultWallet) {
        setSelectedWallets([defaultWallet]);
      }
    }
  }, [participantWallet]);

  useEffect(() => {
    const searchData: SearchWalletRequest = {
      walletFilter: {
        user_ids: [participantWallet?.wallet?.participant?.id?.toString() ?? ""],
        wallet_types: ["GENERAL"]
      },
      from: 0
    };

    dispatch(fetchWalletListAction(searchData));
  }, [participantWallet?.wallet?.participant?.id]);

  const handleSave = () => {
    dispatch(
      blockMerchantAction(
        {
          wallet_ids: selectedWallets.map((wallet) => wallet.id.toString()),
          merchant_id: data?.data?.merchantId
        },
        handleClose
      )
    );
  };

  const handleWalletChange = (event: React.ChangeEvent<object>, newValue: Wallet[]) => {
    const uniqueWallets = newValue.filter((wallet, index, self) => self.findIndex((t) => t.id === wallet.id) === index);

    setSelectedWallets(uniqueWallets);
  };

  const isMerchantBlocked = participantWallet?.wallet?.preferences?.blockedMerchants?.includes(data?.data?.merchantId);

  const handleUnblock = () => {
    const preferenceData = {
      cashWithdrawalAllowed: participantWallet?.wallet?.preferences?.cashWithdrawalAllowed,
      maxAllowedLimit: participantWallet?.wallet?.preferences?.maxAllowedLimit,
      spendFrequency: participantWallet?.wallet?.preferences?.spendFrequency,
      spendLimit: participantWallet?.wallet?.preferences?.spendLimit,
      blockedMccCategories: participantWallet?.wallet?.preferences?.blockedMccCategories ?? [],
      blockedMerchantIds: (participantWallet?.wallet?.preferences?.blockedMerchants ?? []).filter(
        (id) => id !== data?.data?.merchantId
      ),
      walletId: String(participantWallet?.wallet?.id)
    };

    dispatch(changePreferenceAction(preferenceData, handleClose));
  };

  return (
    <div className={styles.referModalBox}>
      <header className={styles.referModalTop}>
        <div className={styles.topL}>
          <h3>Block Merchant</h3>
        </div>
        <div className={styles.topR}>
          <button className={styles.closeBtn} onClick={handleCancel}>
            <CloseIcon />
          </button>
        </div>
      </header>
      {participantWalletLoading ? (
        <Skeleton variant="rectangular" height="200px" className={styles.skeleton} />
      ) : (
        <main className={styles.referModalBottom}>
          <div className={styles.merchant}>
            <div className={styles.merchantItem}>
              <span className={styles.label}>merchantName</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{getTransactionValue(data)?.value ?? "---"}</span>
            </div>
            <div className={styles.merchantItem}>
              <span className={styles.label}>merchantId</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{data?.data?.merchantId ?? "---"}</span>
            </div>
            <div className={styles.merchantItem}>
              <span className={styles.label}>merchantCategoryCode</span>
              <span className={styles.colon}>:</span>
              <span className={styles.value}>{data?.data?.merchantCategoryCode ?? "---"}</span>
            </div>
          </div>
          <div className={styles.user}>
            <Avatar
              id="av"
              aria-label="Recipe"
              style={{ backgroundColor: bgColorbyName(String(participantWallet?.wallet?.participant?.fullName)) }}
              className={styles.userAvatar}
            >
              {participantWallet?.wallet?.participant?.fullName?.charAt(0).toLocaleUpperCase()}
            </Avatar>
            <div className={styles.userDetail}>
              <div className={styles.userName}>{participantWallet?.wallet?.participant?.fullName}</div>
              <div className={styles.userEmail}>{participantWallet?.wallet?.participant?.email}</div>
              <div className={styles.userId}>{participantWallet?.wallet?.participant?.id}</div>
            </div>
          </div>
          <div className={styles.wallet}>
            <label className={styles.label} htmlFor="wallet">
              Wallets
            </label>
            <Autocomplete
              multiple
              id="wallet"
              options={walletList?.list ?? []}
              getOptionLabel={(option) => option?.name ?? option}
              value={selectedWallets}
              onChange={handleWalletChange}
              disableCloseOnSelect
              renderInput={(params) => <TextField {...params} placeholder="Search wallets to block merchant for" />}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>{`${option.name}`}</span>
                    <span style={{ color: "#64748B" }}>{option?.participant?.email}</span>
                  </div>
                </li>
              )}
            />
            {isMerchantBlocked && (
              <div className={styles.blockMessage}>
                {participantWallet?.wallet?.name} is currently blocked for this merchant.
                <button onClick={handleUnblock}> Tap Here</button> to unblock it.
              </div>
            )}
          </div>
        </main>
      )}
      <footer className={styles.footer}>
        <div className={styles.modalbtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleCancel}>
              Close
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleSave} disabled={blockMerchantLoading}>
              {blockMerchantLoading ? <CircularProgress sx={{ color: "#fff" }} /> : "Block"}
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
