/* eslint-disable no-nested-ternary */
import { useState } from "react";
import Button from "@mui/material/Button";
import styles from "./addTransaction.module.scss";
import { Autocomplete, CircularProgress, Stack, TextField } from "@mui/material";
import { debounce } from "lodash";
import {
  addTransactionRecord,
  fetchUserListAction,
  fetchWalletListAction,
  setUsers,
  setWallets
} from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getDecimalNumber } from "src/utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "src/types/commonTypes";
import { immediateToast } from "izitoast-react";
import { Wallet } from "src/page/wallets/walletSlice";
import { adjustTransactionRequest } from "src/network/graphql/transactionService";

interface Props {
  handleClose: () => void;
  scb?: (_value: boolean) => void;
}

export default function AddTransaction({ handleClose, scb }: Props) {
  const dispatch = useAppDispatch();
  const { addTransactionLoading, users, walletList } = useAppSelector((state) => state.transaction);
  const [amount, setAmount] = useState<string>("");
  const [merchant, setMerchant] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<Wallet | null>(null);
  const [selectedPurchaseMadeBy, setSelectedPurchaseMadeBy] = useState<User | null>(null);
  const [sucessLogo, setSuccessLogo] = useState<boolean>(false);

  const handleCancel = () => {
    dispatch(setUsers([]));
    dispatch(setWallets([]));
    handleClose();
  };

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      walletFilter: {
        wallet_types: ["CASH"],
        name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchWalletListAction(data));
  }, 500);
  const handlePurchaseMadeByChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleSave = () => {
    if (!selectedUser || !selectedPurchaseMadeBy || !amount || !merchant) {
      immediateToast("error", {
        message: "Please fill all required fields",
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }

    const data: adjustTransactionRequest = {
      wallet_id: String(selectedUser.id),
      amount: parseFloat(amount),
      merchantName: merchant,
      transaction_user_id: selectedPurchaseMadeBy.id,
      currency: "AUD"
    };

    dispatch(
      addTransactionRecord(data, (value: boolean) => {
        setSuccessLogo(true);
        setTimeout(() => {
          setSuccessLogo(false);
          handleCancel();
          if (scb) scb(value);
        }, 2000);
      })
    );
  };

  return (
    <div className={styles.referModalBox}>
      <header className={styles.referModalTop}>
        <div className={styles.topL}>
          <h3>Add Transaction</h3>
        </div>
        <div className={styles.topR}>
          <button className={styles.closeBtn} onClick={handleCancel}>
            <CloseIcon />
          </button>
        </div>
      </header>
      {addTransactionLoading ? (
        <div className={styles.progress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
          <p className={styles.holdOnText}>
            Hold On! We are creating your transaction<span className={styles.dots}></span>
          </p>
        </div>
      ) : sucessLogo ? (
        <div className={styles.successAnimation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 31 30" fill="#34A853">
            <path d="M27.8549 13.4252L26.1549 11.4502C25.8299 11.0752 25.5674 10.3752 25.5674 9.8752V7.7502C25.5674 6.4252 24.4799 5.3377 23.1549 5.3377H21.0299C20.5424 5.3377 19.8299 5.0752 19.4549 4.7502L17.4799 3.0502C16.6174 2.3127 15.2049 2.3127 14.3299 3.0502L12.3674 4.7627C11.9924 5.0752 11.2799 5.3377 10.7924 5.3377H8.62988C7.30488 5.3377 6.21738 6.4252 6.21738 7.7502V9.8877C6.21738 10.3752 5.95488 11.0877 5.62988 11.4502L3.92988 13.4252C3.15488 14.2502 3.15488 15.5752 3.92988 16.4002L5.62988 18.3752C5.95488 18.7502 6.21738 19.4502 6.21738 19.9502V22.0752C6.21738 23.4002 7.30488 24.4877 8.62988 24.4877H10.7549C11.2424 24.4877 11.9549 24.7502 12.3299 25.0752L14.3049 26.7752C15.1674 27.5127 16.5799 27.5127 17.4549 26.7752L19.4299 25.0627C19.8049 24.7502 20.5174 24.4877 21.0049 24.4877H23.1799C24.5049 24.4877 25.5924 23.4002 25.5924 22.0752V19.9502C25.5924 19.4627 25.8549 18.7502 26.1799 18.3752L27.8799 16.4002C28.6049 15.5752 28.6049 14.2502 27.8549 13.4252ZM12.4424 20.2002L8.15488 15.9127L9.56738 14.5127L12.4424 17.3877L21.4424 8.3877L22.8549 9.7877L12.4424 20.2002Z" />
          </svg>
          <p className={styles.successText}>Transaction Successfully Created!</p>
        </div>
      ) : (
        <>
          <main className={styles.referModalBottom}>
            <form id={styles.scrollBarH}>
              <div className={styles.bottomI}>
                <div className={styles.Amount}>
                  <label htmlFor="amount">{"Amount (in AUD)"}</label>
                  <input
                    value={amount}
                    type="number"
                    id="amount"
                    placeholder="Enter amount"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className={styles.Merchant}>
                  <label htmlFor="merchant">Merchant / Outlet</label>
                  <input
                    value={merchant}
                    type="text"
                    id="merchant"
                    placeholder="Enter where the purchase is made"
                    onChange={(e) => {
                      setMerchant(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div className={styles.bottomII}>
                <div className={styles.User}>
                  <label className={styles.label} htmlFor="user">
                    Wallet
                  </label>
                  <Autocomplete
                    id="user"
                    options={walletList?.list}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option?.name ?? option}
                    value={selectedUser}
                    onInputChange={handleUserChange}
                    onChange={(event, newValue) => setSelectedUser(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search wallet who's money will be used" />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>{`${option.name}`}</span>
                        <span style={{ color: "#64748B" }}>{option?.participant?.email}</span>
                      </li>
                    )}
                  />
                </div>
                <div className={styles.purchasedBy}>
                  <label className={styles.label} htmlFor="purchasemadeby">
                    Purchase Made By
                  </label>
                  <Autocomplete
                    id="purchasemadeby"
                    options={users?.list}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                    value={selectedPurchaseMadeBy}
                    onInputChange={handlePurchaseMadeByChange}
                    onChange={(event, newValue) => setSelectedPurchaseMadeBy(newValue)}
                    renderInput={(params) => <TextField {...params} placeholder="Enter who made the payment" />}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>{`${option.firstName} ${option.lastName}`}</span>
                        <span style={{ color: "#64748B" }}>{option.email}</span>
                      </li>
                    )}
                  />
                </div>
              </div>
              <div className={styles.bottomIII}>
                <div className={styles.Category}>
                  <label className={styles.title}>Balance</label>
                  <div
                    style={{
                      color:
                        selectedUser?.balance === undefined
                          ? ""
                          : selectedUser.balance === 0
                            ? ""
                            : selectedUser.balance > 0
                              ? "green"
                              : "red"
                    }}
                    className={styles.input}
                  >
                    ${getDecimalNumber(Number(selectedUser?.balance ?? 0))}
                  </div>
                </div>
              </div>
            </form>
          </main>
          <footer className={styles.footer}>
            <div className={styles.modalbtn}>
              <Stack spacing={2} direction="row">
                <Button className={styles.cancelBtn} variant="text" onClick={handleCancel}>
                  Close
                </Button>
                <Button className={styles.sendBtn} variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Stack>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}
