import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { sendNotification, sendNotificationRequest } from "src/network/graphql/configService";

const uploadFilesSlice = createSlice({
  name: "uploadFiles",
  initialState: {
    loading: false,
    error: null
  },
  reducers: {
    sendInvitationStart: (state) => {
      state.loading = true;
    },
    sendInvitationSuccess: (state) => {
      state.loading = false;
    },
    sendInvitationFalse: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

const { sendInvitationStart, sendInvitationSuccess, sendInvitationFalse } = uploadFilesSlice.actions;

export const sendCapmpaignProgramEmailAction = (data: sendNotificationRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(sendInvitationStart());
    try {
      await sendNotification(data);

      dispatch(sendInvitationSuccess());
      if (cb) cb();
    } catch (err) {
      dispatch(sendInvitationFalse(err));
    }
  };
};

export default uploadFilesSlice.reducer;
