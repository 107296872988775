import React, { useState } from "react";
import Modalui from "../UI/modal/Modalui";
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Card,
  changeCardPinAction,
  fetchUserListAction,
  setUsersAction,
  updateCardAction,
  updateCardStatusAction
} from "src/page/cards/cardSlice";
import styles from "./cardEdit.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { CAN_CHANGE_CARD_PIN, CAN_TOGGLE_CARD_STATUS, CAN_UPDATE_CARD } from "../constant/constant";
import { CardAccessType, CardStatus } from "src/types/commonTypes";
import PinInput from "react-pin-input";
import { checkPermission } from "src/utils/utils";
import { debounce } from "lodash";
import ToggleSwitch from "../UI/toggleSwitch/ToggleSwitch";
interface Iprops {
  card: Card;
  onUpdate: () => void;
}

function CardEdit({ card, onUpdate }: Iprops) {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pinModalOpen, setpinModalOpen] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [cardActive, setCardActive] = useState<CardStatus>(card.cardStatus as CardStatus);
  const [cardAccessType, setCardAccessType] = useState<CardAccessType>(card?.accessType as CardAccessType);
  const [holderName, setHolderName] = useState<string>(card?.cardHolderName);
  const [changeUser, setChangeUser] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const [error, setError] = useState<string>("");

  const dispatch = useAppDispatch();
  const { updateCardLoading, cardPinLoading, updateStatusLoading, users } = useAppSelector((state) => state.cards);
  const handleClose = () => {
    setOpen(false);
    setChangeUser(false);
    dispatch(setUsersAction([]));
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handlePinClose = () => setpinModalOpen(false);

  // const fetchCardsData = useCallback(() => {
  //   dispatch(
  //     getAllCardsAction({
  //       card_filter: {
  //         card_status: "ACTIVE"
  //       }
  //     })
  //   );
  // }, [dispatch]);

  const handleSave = () => {
    if (!holderName?.trim()) {
      setError("Holder name is required.");
      return;
    }
    const data = {
      card_id: card?.id,
      card_type: cardAccessType,
      holder_name: holderName,
      user_id: changeUser ? userId : card?.user?.id
    };

    dispatch(
      updateCardAction(data, () => {
        handleClose();
        onUpdate();
        // fetchCardsData();
      })
    );
  };

  const handlePinSave = () => {
    if (!pin.trim() || !confirmPin.trim()) {
      setPinError("PIN is required");
      return;
    }

    if (pin.length !== 4) {
      setPinError("PIN must be at least 4 characters long");
      return;
    }

    if (pin !== confirmPin) {
      setPinError("PINs do not match");
      return;
    }

    dispatch(
      changeCardPinAction(card.id, pin, () => {
        setpinModalOpen(false);
        setConfirmPin("");
        setPin("");
      })
    );
  };

  const handlePinChange = (newValue: string) => {
    setPin(newValue);
    if (newValue !== confirmPin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  const handleConfirmPinChange = (newValue: string) => {
    setConfirmPin(newValue);
    if (newValue !== pin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  const handleSetStatus = () => {
    dispatch(
      updateCardStatusAction(card.id, cardActive === "ACTIVE" ? "UNBLOCK" : "BLOCK", () => {
        handleClose();
        onUpdate();
      })
    );
  };

  const handleUserInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleValue = (val?: string | number | boolean) => {
    if (typeof val === "string") {
      setChangeUser(val === "Existing" ? false : true);
    }
  };

  return (
    <>
      {checkPermission(CAN_CHANGE_CARD_PIN || CAN_UPDATE_CARD || CAN_TOGGLE_CARD_STATUS) && (
        <>
          <IconButton onClick={handleMenuOpen} aria-label="Order Card">
            <MoreVertIcon></MoreVertIcon>
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {checkPermission(CAN_UPDATE_CARD) && <MenuItem onClick={() => setOpen(true)}>Update</MenuItem>}
            {checkPermission(CAN_TOGGLE_CARD_STATUS) && (
              <MenuItem onClick={() => setStatusModal(true)}>Toggle Status</MenuItem>
            )}
            {checkPermission(CAN_CHANGE_CARD_PIN) && (
              <MenuItem onClick={() => setpinModalOpen(true)}>Change PIN</MenuItem>
            )}
          </Menu>
        </>
      )}
      <Modalui
        open={statusModal}
        handleClose={() => {
          setStatusModal(false);
        }}
        modaluiOver={styles.editModalOver}
      >
        <div className={styles.filterContainer}>
          <div className={styles.filterContent}>
            <h4>Card Number: {card?.cardNumber}</h4>
            <Select
              value={cardActive}
              onChange={(e) => setCardActive(e.target.value as CardStatus)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="BLOCKED">Inactive</MenuItem>
            </Select>
          </div>
          <div className={styles.bottom}>
            <Button variant="outlined" className={styles.clearBtn} onClick={() => setStatusModal(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className={styles.orderCardBtn}
              onClick={handleSetStatus}
              disabled={updateStatusLoading}
            >
              {updateStatusLoading ? (
                <div className={styles.progress}>
                  <CircularProgress sx={{ color: "#ffff" }} />
                </div>
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </div>
      </Modalui>
      <Modalui open={open} handleClose={handleClose} modaluiOver={styles.editModalOver}>
        <div className={styles.filterContainer}>
          <h4>Card Number: {card?.cardNumber}</h4>
          <div className={styles.filterContent}>
            <div className={styles.holderName}>
              <label>Holder Name</label>
              <input
                type="text"
                placeholder="Holder's Name..."
                value={holderName}
                onChange={(e) => {
                  setHolderName(e.target.value);
                  if (!e.target.value.trim()) {
                    setError("Holder name is required.");
                  } else {
                    setError("");
                  }
                }}
              />
              {error && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error}
                </div>
              )}
            </div>
            <div className={styles.acessType}>
              <label>Access type</label>
              <Select
                value={cardAccessType}
                onChange={(e) => setCardAccessType(e.target.value as CardAccessType)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="PRIVATE">Private</MenuItem>
                <MenuItem value="SHARED">Shared</MenuItem>
              </Select>
            </div>
            <div className={styles.user}>
              <div className={styles.header}>
                <label>User</label>
                <ToggleSwitch
                  leftValueIn={"Existing"}
                  rightValueIn={"Update"}
                  leftValueOut={"Existing"}
                  rightValueOut={"Update"}
                  getValue={handleValue}
                  toggleBodyOver={styles.toggleBodyOver}
                  contentOver={styles.contentOver}
                  className={styles.myToggleSwitch}
                />
              </div>
              <div className={styles.content}>
                {changeUser ? (
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    className={styles.Autocomplete}
                    options={users?.list}
                    filterOptions={(x) => x}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option?.firstName ?? option}
                    onInputChange={handleUserInput}
                    // value={userId}
                    onChange={(event, newValue) => {
                      setUserId(newValue?.id || "");
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>{option?.firstName + " " + option?.lastName}</span>
                        <span style={{ color: "#64748B" }}>{option?.email}</span>
                      </li>
                    )}
                  />
                ) : (
                  <div className={styles.userInfo}>
                    {/* <label>{card?.user?.firstName + " " + card?.user?.lastName}</label> */}
                    <span>{card?.user?.email}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <Button variant="outlined" className={styles.clearBtn} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className={styles.orderCardBtn}
              onClick={handleSave}
              disabled={updateCardLoading}
            >
              {updateCardLoading ? (
                <div className={styles.progress}>
                  <CircularProgress sx={{ color: "#ffff" }} />
                </div>
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </div>
      </Modalui>
      <Modalui open={pinModalOpen} handleClose={handlePinClose} modaluiOver={styles.pinModalOver}>
        <div className={styles.cardPinWrapper}>
          <div className={styles.header}>
            <h4>Card Number: {card?.cardNumber}</h4>
            <IconButton onClick={handlePinClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.body}>
            {cardPinLoading ? (
              <div className={styles.progress}>
                <CircularProgress sx={{ color: "#0f172a" }} />
              </div>
            ) : (
              <>
                <div className={styles.otpInputContainer}>
                  <div className={styles.pinInput}>
                    <label>Enter new PIN:</label>
                    <div className={styles.inputcontainer}>
                      <PinInput
                        length={4}
                        secret
                        secretDelay={500}
                        onChange={handlePinChange}
                        style={{ display: "flex", gap: "10px" }}
                        focus
                      />
                    </div>
                  </div>

                  <div className={styles.pinInput}>
                    <label>Re-enter new PIN:</label>
                    <div className={styles.inputcontainer}>
                      <PinInput
                        length={4}
                        secret
                        secretDelay={500}
                        onChange={handleConfirmPinChange}
                        style={{ display: "flex", gap: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                {pinError && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {pinError}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomContainer}>
            <Stack spacing={2} direction="row">
              <Button className={styles.cancelBtn} variant="text" onClick={() => setpinModalOpen(false)}>
                Cancel
              </Button>
              <Button className={styles.saveBtn} variant="contained" onClick={handlePinSave} disabled={cardPinLoading}>
                Apply Changes
              </Button>
            </Stack>
          </div>
        </div>
      </Modalui>
    </>
  );
}

export default CardEdit;
